import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Box,
  FormHelperText,
  ListItemText,
} from '@mui/material';
import { AssessmentType } from '../../utils/types';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { formats, modules } from "../../components/RichTextEditor";
import { Add } from '@mui/icons-material';
import AddMcqs from './AddMcqs';
import AddCoding from './AddCoding';
import { apiService } from "../../services/Service";
import { handleAuthError } from "../../utils/authUtils";
import { SnackbarMessage } from '../../customHooks/useSnackbar';
import CustomSnackbar from '../../components/CustomSnackbar';
import moment, { duration } from 'moment';

const CreateAssessment: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    type: AssessmentType.Coding, // Initial value from enum
    instructions: '',
    duration: 0,
    startTime: '',
    endTime: '',
    randomizeQuestions: false,
    isProctored: false,
    questions: [] as { questionId: string; type: 'mcq' | 'coding' }[],
    userGroups: [],
  });

  const [errors, setErrors] = useState({
    name: '',
    type: '',
    instructions: '',
    startTime: '',
    endTime: '',
    questions: '',
    duration: '',
  });

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);
  const [selectedMcqs, setSelectedMcqs] = useState<string[]>([]);
  const [selectedCoding, setSelectedCoding] = useState<string[]>([]);
  const [userGroups, setUserGroups] = useState<string[]>([]); // List of available user groups
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]); // Selected groups for new users
  // Handle group selection change
  const handleGroupSelectionChange = (event: any) => {
    const value = event.target.value;
    setSelectedGroups(value);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === 'name' && {
        slug: value.trim().toLowerCase().replace(/\s+/g, '-'),
      }),
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setFormData((prevData) => ({
      ...prevData,
      type: e.target.value as AssessmentType, // Cast to `AssessmentType`
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleEditorChange = (value: string, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const addQuestion = (questionId: string, type: 'mcq' | 'coding') => {
    setFormData((prevData) => {
      const isAlreadyAdded = prevData.questions.some(
        (q) => q.questionId === questionId && q.type === type
      );

      return {
        ...prevData,
        questions: isAlreadyAdded
          ? prevData.questions.filter(
            (q) => !(q.questionId === questionId && q.type === type)
          ) // Remove if already added
          : [...prevData.questions, { questionId, type }], // Add if not already added
      };
    });
  };

  const handleOpenSnackbar = () => {
    setSnackbarMessageInfo({
      type: 'success',
      message: 'Assessment created successfully!',
    });
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  const validate = () => {
    const tempErrors: any = {};
    let valid = true;

    // Validation checks
    if (!formData.name) {
      tempErrors.name = 'Assessment Name is required';
      valid = false;
    }
    if (!formData.type) {
      tempErrors.type = 'Assessment Type is required';
      valid = false;
    }
    if (!formData.duration) {
      tempErrors.duration = 'Duration is required';
      valid = false;
    }
    if (!formData.instructions) {
      tempErrors.instructions = 'Instructions are required';
      valid = false;
    }
    // Handle start and end time validation using moment
    if (!formData.startTime || !moment(formData.startTime, moment.ISO_8601, true).isValid()) {
      tempErrors.startTime = 'Start time is required and should be a valid date';
      valid = false;
    }

    if (!formData.endTime || !moment(formData.endTime, moment.ISO_8601, true).isValid()) {
      tempErrors.endTime = 'End time is required and should be a valid date';
      valid = false;
    } else if (moment(formData.startTime).isSameOrAfter(formData.endTime)) {
      tempErrors.endTime = 'End time must be after start time';
      valid = false;
    }

    // Check if at least one question is added based on the selected type
    if (
      (formData.type === AssessmentType.MCQ && formData.questions.filter(q => q.type === 'mcq').length === 0) ||
      (formData.type === AssessmentType.Coding && formData.questions.filter(q => q.type === 'coding').length === 0) ||
      (formData.type === AssessmentType.Mixed && (formData.questions.filter(q => q.type === 'mcq').length === 0 && formData.questions.filter(q => q.type === 'coding').length === 0))
    ) {
      tempErrors.questions = 'At least one question is required';
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleSubmit = () => {
    if (validate()) {



      setLoading(true);
      const dataForSubmit: any = {
        ...formData,
        startTime: moment(formData.startTime).utc().format(),
        endTime: moment(formData.endTime).utc().format(),
        userGroups: selectedGroups
      }
      apiService.post("/assessments", dataForSubmit)
        .then(() => {
          setLoading(false);
          handleOpenSnackbar();
        })
        .catch((error: any) => {
          setLoading(false);
          setSnackbarMessageInfo({
            type: 'error',
            message: error.response.data.error || "Failed to create Assessment",
          });
          setSnackbarOpen(true);
          handleAuthError({ error: error.response?.data.error }, window.location.href);
        });

    }
  };

  const fetchUserGroups = () => {
    setLoading(true);
    apiService
      .get("/client/user-groups")
      .then((response: any) => {
        setLoading(false);
        const groups = Array.isArray(response.userGroups) ? response.userGroups : [];
        setUserGroups(groups);
      })
      .catch(() => {
        setLoading(false);
        setUserGroups([]);
      });
  };

  useEffect(() => {
    fetchUserGroups();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Create Assessment
      </Typography>
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Assessment Name"
              variant="outlined"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Slug"
              variant="outlined"
              fullWidth
              name="slug"
              value={formData.slug}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={Boolean(errors.type)}>
              <InputLabel>Assessment Type</InputLabel>
              <Select
                value={formData.type}
                onChange={(e: any) => handleSelectChange(e)}
                label="Assessment Type"
                required
              >
                <MenuItem value={AssessmentType.Coding}>Coding</MenuItem>
                <MenuItem value={AssessmentType.MCQ}>MCQ</MenuItem>
                <MenuItem value={AssessmentType.Mixed}>Mixed</MenuItem>
              </Select>
              <FormHelperText>{errors.type}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Instructions
            </Typography>
            <ReactQuill
              value={formData.instructions}
              onChange={(value: any) => handleEditorChange(value, 'instructions')}
              theme="snow"
              placeholder="Enter the instructions here..."
              modules={modules}
              formats={formats}
            />
            {errors.instructions && (
              <Typography color="error" variant="body2">{errors.instructions}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Duration (in minutes)"
              variant="outlined"
              fullWidth
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              required
              error={Boolean(errors.duration)}
              helperText={errors.duration}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Start Time"
              type="datetime-local"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              name="startTime"
              value={moment(formData.startTime).format('YYYY-MM-DDTHH:mm')} // Convert to ISO format before setting value to avoid validation error in MUIformData.startTime}
              onChange={handleChange}
              error={Boolean(errors.startTime)}
              helperText={errors.startTime}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="End Time"
              type="datetime-local"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              name="endTime"
              value={moment(formData.endTime).format('YYYY-MM-DDTHH:mm')} // Convert to ISO format before setting value to avoid validation error in MUI  formData.endTime}
              onChange={handleChange}
              error={Boolean(errors.endTime)}
              helperText={errors.endTime}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.randomizeQuestions}
                  onChange={handleCheckboxChange}
                  name="randomizeQuestions"
                  color="primary"
                />
              }
              label="Randomize Questions"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isProctored}
                  onChange={handleCheckboxChange}
                  name="isProctored"
                  color="primary"
                />
              }
              label="Is Proctored"
            />
          </Grid>

          <Grid item xs={12}>
            <Box mt={3}>
              <Typography variant="h6">Select Groups for Users</Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select Groups</InputLabel>
                <Select
                  multiple
                  value={selectedGroups}
                  onChange={handleGroupSelectionChange}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {userGroups.map((group, index) => (
                    <MenuItem key={index} value={group}>
                      <Checkbox checked={selectedGroups.indexOf(group) > -1} />
                      <ListItemText primary={group} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {formData.type === AssessmentType.MCQ && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  MCQ Questions
                </Typography>
                <AddMcqs addQuestion={addQuestion} selectedQuestions={selectedMcqs} />
              </Box>
            )}

            {formData.type === AssessmentType.Coding && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Coding Questions
                </Typography>
                <AddCoding addQuestion={addQuestion} selectedQuestions={selectedCoding} />
              </Box>
            )}

            {formData.type === AssessmentType.Mixed && (
              <>
                <Box mb={3}>
                  <Typography variant="h6" gutterBottom>
                    MCQ Questions
                  </Typography>
                  <AddMcqs addQuestion={addQuestion} selectedQuestions={selectedMcqs} />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" gutterBottom>
                    Coding Questions
                  </Typography>
                  <AddCoding addQuestion={addQuestion} selectedQuestions={selectedCoding} />
                </Box>
              </>
            )}
            {errors.questions && (
              <Typography color="error" variant="body2">{errors.questions}</Typography>
            )}

          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              startIcon={<Add />}
            >
              Create Assessment
            </Button>
          </Grid>
        </Grid>
      </form>
      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default CreateAssessment;

