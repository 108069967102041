import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { apiService } from "../../../services/Service";
import { handleAuthError } from "../../../utils/authUtils";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  Chip,
  Avatar,
  Button,
} from "@mui/material";
import {
  AccessTime,
  Event,
  HourglassEmpty,
  Schedule,
  VerifiedUser,
  Timer,
} from "@mui/icons-material";

const formatTimeRemaining = (milliseconds: number): string => {
  if (milliseconds <= 0) return "Test Ended";

  const seconds = Math.floor(milliseconds / 1000) % 60;
  const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
  const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24)) % 365;
  const years = Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 365));

  return `${years > 0 ? `${years}y ` : ""}${days > 0 ? `${days}d ` : ""}${hours}h ${minutes}m ${seconds}s`;
};

const AssessmentDetailsPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const [assessment, setAssessment] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState<string>("");
  const [testStarted, setTestStarted] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await apiService.get(`/assessments/details/${slug}`);
        setAssessment(response.assessment);
        setLoading(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          handleAuthError({ error: error.response?.data.error }, window.location.href);
        }
      }
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    if (!assessment) return;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const startTime = new Date(assessment.startTime).getTime();
      const endTime = new Date(assessment.endTime).getTime();

      if (now < startTime) {
        setTimeRemaining(formatTimeRemaining(startTime - now));
      } else if (now > endTime) {
        setTimeRemaining("Test Ended");
        clearInterval(timer);
      } else {
        setTimeRemaining("Test in Progress");
        setTestStarted(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [assessment]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!assessment) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6" color="error">
          Assessment not found.
        </Typography>
      </Box>
    );
  }

  const now = new Date().getTime();
  const hasStarted = now >= new Date(assessment.startTime).getTime();
  const hasEnded = now > new Date(assessment.endTime).getTime();

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {/* Header Section */}
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Avatar
                  src={assessment.createdBy?.picture}
                  alt={assessment.createdBy?.fullname}
                  sx={{ width: 64, height: 64 }}
                />
                <Typography variant="h4" fontWeight="bold">
                  {assessment.name}
                </Typography>
                <Typography color="text.secondary">
                  Created by {assessment.createdBy?.fullname}
                </Typography>

                <Divider sx={{ my: 2, width: "100%" }} />

                {testStarted ? (
                  <Button variant="contained" color="primary" size="large" onClick={() => {
                    navigate(`/assessment/${assessment.slug}`);
                  }}>
                    Start Test
                  </Button>
                ) : (
                  <Chip
                    label={timeRemaining}
                    color={timeRemaining === "Test Ended" ? "error" : "primary"}
                    icon={<Timer />}
                    sx={{ fontSize: "1rem", px: 2 }}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Main Content Section */}
        <Grid item xs={12} md={8}>
          {/* Instructions */}
          <Card elevation={3} sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Instructions
              </Typography>
              <Box
                dangerouslySetInnerHTML={{ __html: assessment.instructions }}
                sx={{
                  p: 2,
                  borderRadius: 1,
                  border: "1px solid #e0e0e0",
              
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Test Details */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} sx={{ height: "100%" , maxHeight: "400px",
                  overflowY: "auto", }}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Test Details
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <VerifiedUser color="primary" />
                  <Typography>Proctored:</Typography>
                </Box>
                <Typography>{assessment.isProctored ? "Yes" : "No"}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Schedule color="primary" />
                  <Typography>Randomized Questions:</Typography>
                </Box>
                <Typography>{assessment.randomizeQuestions ? "Yes" : "No"}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Event color="primary" />
                  <Typography>Start Time:</Typography>
                </Box>
                <Typography>{new Date(assessment.startTime).toLocaleString()}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <HourglassEmpty color="primary" />
                  <Typography>End Time:</Typography>
                </Box>
                <Typography>{new Date(assessment.endTime).toLocaleString()}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Timer color="primary" />
                  <Typography>Duration:</Typography>
                </Box>
                <Typography>{assessment.duration} mins</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AssessmentDetailsPage;
