// src/components/Login.tsx
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import { useGoogleLogin } from "@react-oauth/google";
import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { apiService } from "../services/Service";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../components/Logo";

interface GoogleLoginResponse {
  access_token: string;
}

interface ApiResponse {
  token: string;
  user: {
    email: string;
    name: string;
    picture: string;
  };
}

const LoginContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  width: "100vw", // Ensures it takes full width of the viewport
  padding: 0, // Removes default padding
}));

const LoginPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: "400px",
  width: "100%",
  textAlign: "center",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
}));

const msalConfig = {
  auth: {
    clientId: "6bed32b8-68ac-4b01-95e1-be4e3841b040",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${window.location.origin}/auth/microsoft/callback`,
  },
  cache: {
    cacheLocation: "sessionStorage", // Use sessionStorage for better security
    storeAuthStateInCookie: true, // Set to true for IE11 or older browsers
  },
};


const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState<string>("/profile"); // Default to /dashboard if no redirect URL is provided
  const pca = new PublicClientApplication(msalConfig);

  // Ensure MSAL is initialized
  useEffect(() => {
    (async () => {
      try {
        await pca.initialize();
      } catch (error) {
        console.error("MSAL Initialization Error:", error);
      }
    })();
  }, [pca]);
  useEffect(() => {
    // Extract the redirect URL from the query parameters
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    if (redirect) {
      setRedirectUrl(redirect);
    }
  }, [location.search]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (googleResponse: GoogleLoginResponse) => {
      try {
        const response: ApiResponse = await apiService.post("/auth/google", {
          token: googleResponse.access_token,
        });

        // Store the token in local storage
        localStorage.setItem("jwt_token", response.token);
        // Store the user data in local storage
        localStorage.setItem("user", JSON.stringify(response.user));

        window.location.href = redirectUrl;
      } catch (error) {
        console.error("Error during Google Login:", error);
      }
    },
    onError: () => {
      console.error("Google Login Failed");
    },
  });

  const handleMicrosoftLogin = async () => {
    try {
      const loginResponse: AuthenticationResult = await pca.loginPopup({
        scopes: ["user.read"],
      });
      const accessToken = loginResponse.accessToken;
      const response: any = await apiService.post("/auth/microsoft", {
        token: accessToken,
      });

      // Store JWT token in local storage
      localStorage.setItem("jwt_token", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));
      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Error during Microsoft login:", error);
    }
  };

  return (
    <LoginContainer>
    <LoginPaper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
          alignItems: "center",
          color: "black",
        }}
      >
        <Logo />
      </div>
  
      <Box display="flex" flexDirection="column" gap={2}>
        {/* Google Login Button */}
        <Button
          variant="outlined"
          fullWidth
          onClick={() => handleGoogleLogin()}
          startIcon={
            <FaGoogle
              style={{
                color: "#DB4437", // Google Red
              }}
              size={20}
            />
          }
          sx={{
            borderColor: "#DB4437",
            color: "#DB4437",
            "&:hover": {
              backgroundColor: "#FEE8E6",
              borderColor: "#DB4437",
            },
          }}
        >
          Login with Google
        </Button>
  
        {/* Microsoft Login Button */}
        <Button
          variant="outlined"
          fullWidth
          onClick={() => handleMicrosoftLogin()}
          startIcon={
            <FaMicrosoft
              style={{
                color: "#00A4EF", // Microsoft Blue
              }}
              size={20}
            />
          }
          sx={{
            borderColor: "#00A4EF",
            color: "#00A4EF",
            "&:hover": {
              backgroundColor: "#E6F4FE",
              borderColor: "#00A4EF",
            },
          }}
        >
          Login with Microsoft
        </Button>
      </Box>
    </LoginPaper>
  </LoginContainer>
  
  );
};

export default Login;
