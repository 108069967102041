import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Typography,
  Container,
  CircularProgress,
  Box,
  Snackbar,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Save, Clear } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { apiService } from "../../../services/Service"; // Ensure this path is correct
import { handleAuthError } from "../../../utils/authUtils"; // Ensure this path is correct

interface ClientFormData {
  clientType: "college" | "university" | "industry";
  officialName: string;
  shortName: string;
  address: string;
  authorizedPersonName: string;
  authorizedContactNumber: string;
  designation: string;
  authorizedEmailId: string;
  officialEmailId: string;
  razorpayOrderId?: string;
  razorpayPaymentId?: string;
  razorpaySignature?: string;
  isApproved: boolean;
  numberOfAssessments: number;
  numberOfUsers: number;
  validity: Date;
  levels: string[];
}

const initialClientData: ClientFormData = {
  clientType: "college",
  officialName: "",
  shortName: "",
  address: "",
  authorizedPersonName: "",
  authorizedContactNumber: "",
  designation: "",
  authorizedEmailId: "",
  officialEmailId: "",
  isApproved: false,
  numberOfAssessments: 0,
  numberOfUsers: 0,
  validity: new Date(),
  levels: [],
};

const CreateClient: React.FC = () => {
  const [formData, setFormData] = useState<ClientFormData>(initialClientData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.officialName.trim()) newErrors.officialName = "Official Name is required.";
    if (!formData.shortName.trim()) newErrors.shortName = "Short Name is required.";
    if (!formData.address.trim()) newErrors.address = "Address is required.";
    if (!formData.authorizedPersonName.trim()) newErrors.authorizedPersonName = "Authorized Person Name is required.";
    if (!formData.authorizedContactNumber.trim()) {
      newErrors.authorizedContactNumber = "Authorized Contact Number is required.";
    } else if (!/^\+?\d{1,3}[- ]?\d{10}$/.test(formData.authorizedContactNumber)) {
      newErrors.authorizedContactNumber = "Invalid Contact Number. Must be 10 digits with an optional country code (e.g., +91-1234567890).";
    }
    
    
    if (!formData.designation.trim()) newErrors.designation = "Designation is required.";
    if (!formData.authorizedEmailId.trim()) {
      newErrors.authorizedEmailId = "Authorized Email ID is required.";
    } else if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(formData.authorizedEmailId)) {
      newErrors.authorizedEmailId = "Invalid Email ID format.";
    }
    if (!formData.officialEmailId.trim()) {
      newErrors.officialEmailId = "Official Email ID is required.";
    } else if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(formData.officialEmailId)) {
      newErrors.officialEmailId = "Invalid Email ID format.";
    }
    if (formData.numberOfAssessments < 0) {
      newErrors.numberOfAssessments = "Number of Assessments cannot be negative.";
    }
    if (formData.numberOfUsers < 0) {
      newErrors.numberOfUsers = "Number of Users cannot be negative.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "date" ? new Date(value) : value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
  
    // Ensure valid level is passed (e.g., LV1, LV2, etc.)
    if (!["LV1", "LV2", "LV3", "LV4"].includes(name)) return;
  
    setFormData((prevData) => {
      const newLevels = checked
        ? [...prevData.levels, name] // Add the level if checked
        : prevData.levels.filter((lvl) => lvl !== name); // Remove the level if unchecked
  
      return { ...prevData, levels: newLevels };
    });
  };
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    console.log(formData);

    setLoading(true);
    apiService
      .post("/admin/client", formData)
      .then(() => {
        setLoading(false);
        setSnackbarMessage("Client created successfully!");
        setSnackbarOpen(true);
        setFormData(initialClientData);
      })
      .catch((error: any) => {
        setLoading(false);
        setSnackbarMessage(
          error.response?.data.error || "Failed to create client"
        );
        setSnackbarOpen(true);
        handleAuthError(
          { error: error.response?.data.error },
          window.location.href
        );
      });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom align="center">
            Create New Client
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Client Type"
                  variant="outlined"
                  fullWidth
                  name="clientType"
                  value={formData.clientType}
                  onChange={handleInputChange}
                  select
                  SelectProps={{ native: true }}
                >
                  <option value="college">college</option>
                  <option value="university">university</option>
                  <option value="industry">industry</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Official Name"
                  variant="outlined"
                  fullWidth
                  name="officialName"
                  value={formData.officialName}
                  onChange={handleInputChange}
                  error={!!errors.officialName}
                  helperText={errors.officialName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Short Name"
                  variant="outlined"
                  fullWidth
                  name="shortName"
                  value={formData.shortName}
                  onChange={handleInputChange}
                  error={!!errors.shortName}
                  helperText={errors.shortName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Authorized Person Name"
                  variant="outlined"
                  fullWidth
                  name="authorizedPersonName"
                  value={formData.authorizedPersonName}
                  onChange={handleInputChange}
                  error={!!errors.authorizedPersonName}
                  helperText={errors.authorizedPersonName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Authorized Contact Number"
                  variant="outlined"
                  fullWidth
                  name="authorizedContactNumber"
                  value={formData.authorizedContactNumber}
                  onChange={handleInputChange}
                  error={!!errors.authorizedContactNumber}
                  helperText={errors.authorizedContactNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Designation"
                  variant="outlined"
                  fullWidth
                  name="designation"
                  value={formData.designation}
                  onChange={handleInputChange}
                  error={!!errors.designation}
                  helperText={errors.designation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Authorized Email ID"
                  variant="outlined"
                  fullWidth
                  name="authorizedEmailId"
                  type="email"
                  value={formData.authorizedEmailId}
                  onChange={handleInputChange}
                  error={!!errors.authorizedEmailId}
                  helperText={errors.authorizedEmailId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Official Email ID"
                  variant="outlined"
                  fullWidth
                  name="officialEmailId"
                  type="email"
                  value={formData.officialEmailId}
                  onChange={handleInputChange}
                  error={!!errors.officialEmailId}
                  helperText={errors.officialEmailId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Number of Assessments"
                  variant="outlined"
                  fullWidth
                  name="numberOfAssessments"
                  type="number"
                  value={formData.numberOfAssessments}
                  onChange={handleInputChange}
                  error={!!errors.numberOfAssessments}
                  helperText={errors.numberOfAssessments}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Number of Users"
                  variant="outlined"
                  fullWidth
                  name="numberOfUsers"
                  type="number"
                  value={formData.numberOfUsers}
                  onChange={handleInputChange}
                  error={!!errors.numberOfUsers}
                  helperText={errors.numberOfUsers}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Validity"
                  variant="outlined"
                  fullWidth
                  name="validity"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formData.validity.toISOString().substring(0, 10)}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Select Levels</Typography>
                {["LV1", "LV2", "LV3", "LV4"].map((level) => (
                  <FormControlLabel
                    key={level}
                    control={
                      <Checkbox
                        checked={formData.levels.includes(level)}
                        onChange={handleCheckboxChange}
                        name={level}
                      />
                    }
                    label={level}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isApproved}
                      name="isApproved"
                      onChange={(e) => setFormData({ ...formData, isApproved: e.target.checked })}
                    />
                  }
                  label="Is Approved"
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={loading ? <CircularProgress size={20} /> : <Save />}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setFormData(initialClientData)}
            startIcon={<Clear />}
            disabled={loading}
          >
            Clear
          </Button>
        </CardActions>
      </Card>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default CreateClient;
