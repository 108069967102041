import { useEffect, useState } from "react";
import { debounce } from "lodash";

interface UseWebGazerOptions {
    onOutOfViewport?: () => void; // Callback for gaze-out events
}

const useWebGazer = ({ onOutOfViewport }: UseWebGazerOptions = {}) => {
    const [isCalibrated, setIsCalibrated] = useState(false);
    const [calibrationPoints, setCalibrationPoints] = useState<number[]>([]);

    const calibrationPositions = [
        { id: 1, x: window.innerWidth / 2, y: window.innerHeight / 2 }, // Center
        { id: 2, x: 50, y: 50 }, // Top-left
        { id: 3, x: window.innerWidth / 2, y: 50 }, // Top-middle
        { id: 4, x: window.innerWidth - 50, y: 50 }, // Top-right
        { id: 5, x: 50, y: window.innerHeight / 2 }, // Left-middle
        { id: 6, x: 50, y: window.innerHeight - 50 }, // Bottom-left
        { id: 7, x: window.innerWidth / 2, y: window.innerHeight - 50 }, // Bottom-middle
        { id: 8, x: window.innerWidth - 50, y: window.innerHeight - 50 }, // Bottom-right
        { id: 9, x: window.innerWidth - 50, y: window.innerHeight / 2 }, // Right-middle
    ];

    useEffect(() => {
        if (isCalibrated) {
            let webgazer: any;
            window.saveDataAccrossSessions = true;

            const initializeWebGazer = async () => {
                const webgazerModule = await import("webgazer");
                webgazer = webgazerModule.default;

                (window as any).webgazer = webgazer;
                webgazer
                    .setGazeListener(
                        debounce((data: any) => {
                            if (data) {
                                const { x, y } = data;
                            
                                const screenWidth = window.innerWidth;
                                const screenHeight = window.innerHeight;
                                const tolerance = 10;
                            
                                const outOfViewport =
                                    x < -tolerance || // Left edge
                                    x > screenWidth + tolerance || // Right edge
                                    y < -tolerance; // Top edge only; ignore the bottom boundary entirely
                            
                                if (outOfViewport) {
                                    onOutOfViewport?.();
                                }
                            }
                            
                        }, 10)
                    )
                    .showVideoPreview(false)
                    .begin();
            };

            initializeWebGazer();

            return () => {
                if (webgazer) webgazer.end();
            };
        }
    }, [isCalibrated, onOutOfViewport]);

    const handleCalibrationClick = (id: number) => {
        if (!calibrationPoints.includes(id)) {
            setCalibrationPoints((prev) => [...prev, id]);
            if (calibrationPoints.length + 1 === calibrationPositions.length) {
                setIsCalibrated(true);
            }
        }
    };

    const calibrationUI =  (
        <div
            style={{
                textAlign: "center",
                width: "100vw",
                height: "100vh",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <h4>Screen Calibration</h4>
            <p>Click on all points to calibrate your screen.</p>
            {calibrationPositions.map((point) => (
                <button
                    key={point.id}
                    style={{
                        position: "absolute",
                        left: point.x,
                        top: point.y,
                        transform: "translate(-50%, -50%)",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        backgroundColor: calibrationPoints.includes(point.id)
                            ? "green"
                            : "red",
                    }}
                    onClick={() => handleCalibrationClick(point.id)}
                />
            ))}
        </div>
    );

    return { isCalibrated, calibrationUI };
};

export default useWebGazer;
