import React from "react";
import { Box, Typography, Avatar, Button, Paper, useMediaQuery } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme } from "@mui/material/styles";

interface ProfileHeaderProps {
  fullname: string;
  email: string;
  picture?: string;
  role: string;
  onLogout: () => void;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  fullname,
  email,
  picture,
  role,
  onLogout,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ py: 4, px: 2, backgroundColor: theme.palette.background.default }}>
      <Paper
        elevation={6}
        sx={{
          padding: isSmallScreen ? 2 : 4,
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "center",
          gap: isSmallScreen ? 3 : 4,
          borderRadius: "16px",
          boxShadow: `0 8px 16px ${theme.palette.action.hover}`,
        }}
      >
        <Avatar
          src={picture || ""}
          alt={fullname}
          sx={{
            width: isSmallScreen ? 80 : 120,
            height: isSmallScreen ? 80 : 120,
            boxShadow: `0 4px 8px ${theme.palette.action.hover}`,
            border: `2px solid ${theme.palette.secondary.main}`,
          }}
        />
        <Box sx={{ flexGrow: 1, textAlign: isSmallScreen ? "center" : "left" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 1,
              wordBreak: "break-word",
            }}
          >
            Welcome back, <strong>{fullname}</strong>
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            mt={1}
            justifyContent={isSmallScreen ? "center" : "flex-start"}
          >
            <EmailIcon color="action" />
            <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {email}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            mt={1}
            justifyContent={isSmallScreen ? "center" : "flex-start"}
          >
            <PersonIcon color="action" />
            <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
              Role: <strong>{role}</strong>
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={onLogout}
          sx={{
            alignSelf: isSmallScreen ? "center" : "flex-start",
            mt: isSmallScreen ? 2 : 0,
            width: isSmallScreen ? "100%" : "auto",
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            borderRadius: "8px",
            padding: isSmallScreen ? "10px 16px" : "12px 24px",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
            boxShadow: `0 4px 8px ${theme.palette.action.hover}`,
          }}
        >
          Logout
        </Button>
      </Paper>
    </Box>
  );
};

export default ProfileHeader;
