import React, { useState, useEffect } from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Grid,
    Box,
    SelectChangeEvent,
    Container,
    CircularProgress,
    ListSubheader,
    DialogContent,
    Dialog,
    DialogTitle,
    TextField,
    DialogActions,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { apiService } from "../../services/Service";
import CustomSnackbar from "../../components/CustomSnackbar";
import { SnackbarMessage } from "../../customHooks/useSnackbar";
import { difficultyLevels } from "../../constants/difficultyLevels";
import { handleAuthError } from "../../utils/authUtils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import FollowUpsSection from "./FollowUpsSection";

interface MCQFormData {
    question: string;
    options: string[];
    correctAnswerText: string;
    difficultyLevel: string;
    tags: string[];
    explanation?: string;
    level: string;
    status: string;
    followUps?: { comment: string, addedBy: any,timestamp:string}[];
}
interface Section {
    section: string;
    tags: string[];
}
const initialMCQData: MCQFormData = {
    question: "",
    options: ["", ""], // Minimum two options
    correctAnswerText: "",
    difficultyLevel: "",
    tags: [],
    explanation: "",
    level: "LV4",
    status: "",
    followUps: [], // Ensure followUps is always an array
};

const ReviewMCQ: React.FC = () => {
    const { mcqId } = useParams<{ mcqId: string }>();
    const [formData, setFormData] = useState<MCQFormData>(initialMCQData);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);
    const [tagOptions, setTagOptions] = useState<Section[]>([]);
    const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
    const [rejectionComment, setRejectionComment] = useState("");
    const updateMCQStatus = (status: string, comment?: string) => {
        setLoading(true);
        const payload: any = { status };
        if (comment) payload.followUp = { comment };

        apiService.put(`/moderator/review/mcq/id/${mcqId}`, payload)
            .then(() => {
                setLoading(false);
                setSnackbarMessageInfo({ type: "success", message: `MCQ ${status} successfully!` });
                setSnackbarOpen(true);
                setRejectionDialogOpen(false);
            })
            .catch(() => {
                setLoading(false);
                setSnackbarMessageInfo({ type: "error", message: "Failed to update MCQ status." });
                setSnackbarOpen(true);

            });
    };
    const handleAccept = () => {
        updateMCQStatus("accepted");
    };

    const handleReject = () => {
        if (!rejectionComment.trim()) {
            setSnackbarMessageInfo({ type: "error", message: "Rejection comment is required." });
            setSnackbarOpen(true);
            return;
        }
        updateMCQStatus("rejected", rejectionComment);
    };

    useEffect(() => {
        if (mcqId) {
            apiService.get(`/mcq/${mcqId}`)
                .then((response: any) => {
                    const data = response.data;

                    // Ensure levels is set to a default value if it doesn't exist
                    const updatedData = {
                        ...data,
                        levels: data.levels || ['LV4'], // Default to ['LV4'] if levels is not present
                    };

                    setFormData(updatedData);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setSnackbarMessageInfo({
                        type: "error",
                        message: error.response?.data.error || "Failed to fetch MCQ data.",
                    });
                    setSnackbarOpen(true);
                    handleAuthError({ error: error.response?.data.error }, window.location.href);
                });
        }
    }, [mcqId]);

    const handleOpenSnackbar = () => {
        setSnackbarMessageInfo({
            type: "success",
            message: "MCQ updated successfully!",
        });
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
        setSnackbarMessageInfo(null);
    };

    const handleEditorChange = (value: string, field: keyof MCQFormData) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleOptionsChange = (index: number, value: string) => {
        setFormData((prevData) => {
            const updatedOptions = [...prevData.options];
            updatedOptions[index] = value;
            return { ...prevData, options: updatedOptions };
        });
    };

    const handleAddOption = () => {
        setFormData((prevData) => ({
            ...prevData,
            options: [...prevData.options, ""],
        }));
    };

    const handleRemoveOption = (index: number) => {
        setFormData((prevData) => ({
            ...prevData,
            options: prevData.options.filter((_, i) => i !== index),
        }));
    };

    const fetchSections = () => {
        setLoading(true);
        apiService
            .get(`/tags/mcq/sections`) // Replace this endpoint with your actual backend endpoint
            .then((response: any) => {
                // Set the sections with their tags
                setTagOptions(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setSnackbarMessageInfo({
                    type: "error",
                    message: error.response?.data?.error || "Failed to fetch section details",
                });
                setSnackbarOpen(true);
            });
    };
    useEffect(() => {
        fetchSections();
    }, []);


    if (loading) {
        return (
            <Container maxWidth="md">
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    padding: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 4,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    marginBottom: 4,
                    marginTop: 4,
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "800px",
                    width: "100%",
                    boxSizing: "border-box",
                }}
            >
                <Typography variant="h4" gutterBottom align="center">
                    Review MCQ
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ReactQuill
                            value={formData.question}
                            onChange={(value: any) => handleEditorChange(value, 'question')}
                            theme="snow"
                            placeholder="Enter the question here..."
                            readOnly={true}
                        />
                    </Grid>

                    {formData.options.map((option, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <ReactQuill
                                value={option}
                                onChange={(value: any) => handleOptionsChange(index, value)}
                                theme="snow"
                                placeholder={`Enter Option ${index + 1}...`}
                                readOnly={true}
                            />
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleRemoveOption(index)}
                                sx={{ mt: 1 }}
                                disabled={true}
                            >
                                Remove Option
                            </Button>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddOption}
                            disabled={true}
                        >
                            Add Option
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <ReactQuill
                            value={formData.correctAnswerText}
                            onChange={(value: any) => handleEditorChange(value, 'correctAnswerText')}
                            theme="snow"
                            placeholder="Enter the correct answer here..."
                            readOnly={true}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" disabled={true}>
                            <InputLabel>Difficulty Level</InputLabel>
                            <Select
                                name="difficultyLevel"
                                value={formData.difficultyLevel}
                                onChange={(e: SelectChangeEvent<string>) => handleEditorChange(e.target.value, 'difficultyLevel')}
                                label="Difficulty Level"
                            >
                                {difficultyLevels.map((level) => (
                                    <MenuItem key={level} value={level}>
                                        {level}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" disabled={true}>
                            <InputLabel>Tags</InputLabel>
                            <Select
                                name="tags"
                                multiple
                                value={formData.tags}
                                onChange={(e: SelectChangeEvent<string[]>) => {
                                    const { value } = e.target;
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        tags: typeof value === "string" ? value.split(",") : value,
                                    }));
                                }}
                                label="Tags"
                                renderValue={(selected) => (selected as string[]).join(", ")}
                            >
                                {tagOptions.flatMap((section: Section) => [
                                    <ListSubheader key={`${section.section}-header`}>{section.section}</ListSubheader>,
                                    ...section.tags.map((tag: string) => (
                                        <MenuItem key={tag} value={tag}>
                                            {tag}
                                        </MenuItem>
                                    )),
                                ])}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12}>
                        <ReactQuill
                            value={formData.explanation || ""}
                            onChange={(value: any) => handleEditorChange(value, 'explanation')}
                            theme="snow"
                            placeholder="Enter explanation (optional)..."
                            readOnly={true}
                        />
                    </Grid>

                    <Dialog open={rejectionDialogOpen} onClose={() => setRejectionDialogOpen(false)}>
                        <DialogTitle>Provide a Rejection Comment</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={rejectionComment}
                                onChange={(e) => setRejectionComment(e.target.value)}
                                placeholder="Explain why this MCQ is rejected..."
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setRejectionDialogOpen(false)}>Cancel</Button>
                            <Button onClick={handleReject} color="error">
                                Reject
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

                <FollowUpsSection formData={{ ...formData, followUps: formData.followUps || [] }} />


                   
              



                {!loading ? (
                    <Box sx={{ mt: 2 }}>
                        <Button variant="contained" color="success" onClick={handleAccept}>
                            Accept
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setRejectionDialogOpen(true)}
                            sx={{ ml: 2 }}
                        >
                            Reject
                        </Button>
                    </Box>
                ) : (
                    <CircularProgress />
                )}
            </Box>
            <CustomSnackbar
                open={snackbarOpen}
                messageInfo={snackbarMessageInfo}
                handleClose={handleCloseSnackbar}
            />
        </Container>
    );
};

export default ReviewMCQ;
