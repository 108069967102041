import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Box,
  Divider,
  Avatar,
  Button,
} from "@mui/material";
import {
  Business,
  Email,
  LocationOn,
  ContactPhone,
  Assignment,
  VerifiedUser,
  DateRange,
  Done,
  Group,
  AccountCircle,
  Description,
  Logout,
  CreditCard,
  Receipt,
  Layers,
  LocalOffer,
} from "@mui/icons-material";
import ProfileHeader from "../components/ProfileHeader";
import { useUser } from "../contextApi/UserContext";
import { apiService } from "../services/Service";

const ProfileDashboard: React.FC = () => {
  const { user } = useUser();

  const handleLogout = () => {
    localStorage.removeItem("jwt_token");
    window.location.href = "/login";
  };

  useEffect(() => {
    if (!user) {
      localStorage.removeItem("jwt_token");
      window.location.href = "/login";
    }
  }, [user]);

  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response: any = await apiService.get("/user/profile");
        setUserData(response.user);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user data:", error);
      }
    };
    fetchUser();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" fontWeight="bold">
          <VerifiedUser sx={{ color: "primary.main", mr: 1 }} />
          Profile Dashboard
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Logout />}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>

      {/* Profile Header */}
      <ProfileHeader
        fullname={user?.fullname || ""}
        email={user?.email || ""}
        picture={user?.picture}
        role={user?.role || ""}
        onLogout={handleLogout}
      />

      {/* Loader */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={6}>
          <CircularProgress size={50} />
          <Typography variant="body1" sx={{ ml: 2 }}>
            Loading profile details...
          </Typography>
        </Box>
      ) : (
        userData && userData?.clientData && (
          <Box mt={6}>
            {/* Client Info Card */}
            <Card elevation={3} sx={{ borderRadius: "12px", overflow: "hidden" }}>
              <CardContent>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  <Business sx={{ color: "primary.main", mr: 1 }} />
                  Client Information
                </Typography>
                <Divider sx={{ mb: 3 }} />

                {/* Grid Layout for Client Details */}
                <Grid container spacing={3}>
                  {[
                    {
                      label: "Client Type",
                      value: userData?.clientData?.clientType,
                      icon: <Description />,
                    },
                    {
                      label: "Official Name",
                      value: userData?.clientData?.officialName,
                      icon: <Business />,
                    },
                    {
                      label: "Short Name",
                      value: userData?.clientData?.shortName,
                      icon: <Business />,
                    },
                    {
                      label: "Address",
                      value: userData?.clientData?.address,
                      icon: <LocationOn />,
                    },
                    {
                      label: "Authorized Person",
                      value: userData?.clientData?.authorizedPersonName,
                      icon: <AccountCircle />,
                    },
                    {
                      label: "Contact Number",
                      value: userData?.clientData?.authorizedContactNumber,
                      icon: <ContactPhone />,
                    },
                    {
                      label: "Designation",
                      value: userData?.clientData?.designation,
                      icon: <Description />,
                    },
                    {
                      label: "Authorized Email",
                      value: userData?.clientData?.authorizedEmailId,
                      icon: <Email />,
                    },
                    {
                      label: "Official Email",
                      value: userData?.clientData?.officialEmailId,
                      icon: <Email />,
                    },
                    {
                      label: "Razorpay Order ID",
                      value: userData?.clientData?.razorpayOrderId,
                      icon: <CreditCard />,
                    },
                    {
                      label: "Razorpay Payment ID",
                      value: userData?.clientData?.razorpayPaymentId,
                      icon: <Receipt />,
                    },
                    {
                      label: "Is Approved",
                      value: userData?.clientData?.isApproved ? "Yes" : "No",
                      icon: <Done />,
                    },
                    {
                      label: "Number of Assessments",
                      value: userData?.clientData?.numberOfAssessments,
                      icon: <Assignment />,
                    },
                    {
                      label: "Number of Users",
                      value: userData?.clientData?.numberOfUsers,
                      icon: <Group />,
                    },
                    {
                      label: "Validity",
                      value: new Date(userData?.clientData?.validity).toLocaleDateString(),
                      icon: <DateRange />,
                    },
                    {
                      label: "Levels",
                      value: userData?.clientData?.levels.join(", "),
                      icon: <Layers />,
                    },
                    {
                      label: "Has Availed Free Trial",
                      value: userData?.clientData?.hasAvailedFreeTrial ? "Yes" : "No",
                      icon: <LocalOffer />,
                    },
                    {
                      label: "Users Count",
                      value: userData?.clientData?.usersCount,
                      icon: <Group />,
                    },
                    {
                      label: "Assessments Count",
                      value: userData?.clientData?.assessmentsCount,
                      icon: <Assignment />,
                    },
                  ].map((item, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box display="flex" alignItems="center">
                        <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>{item.icon}</Avatar>
                        <Typography>
                          <strong>{item.label}:</strong> {item.value || "Not Available"}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )
      )}
    </Container>
  );
};

export default ProfileDashboard;
