import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Paper } from "@mui/material";
import CreateUsers from "./CreateUsers";
import CreateGroupUsers from "./CreateGroupUsers";

const TabPanel = ({ children, value, index }: { children: React.ReactNode; value: number; index: number }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const CreateUsersTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: (theme) => theme.shadows[6],
        maxWidth: 800,
        margin: "0 auto",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
      >
        <Tab label="Create Users" />
        <Tab label="Create Group Users" />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <CreateUsers />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <CreateGroupUsers />
      </TabPanel>
    </Paper>
  );
};

export default CreateUsersTabs;
