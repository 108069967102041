import { useEffect, useState, useRef } from 'react';

interface useBaseProctoringOptions {
  onTabSwitch?: () => void; // Callback when the user switches tabs
}

const useBaseProctoring = ({ onTabSwitch }: useBaseProctoringOptions) => {
  const [tabSwitches, setTabSwitches] = useState(0); // State to expose tab switch count
  const tabSwitchCount = useRef(0); // Ref to keep track of the tab switch count

  // Predefined keys to disable
  const allKeysToDisable = [
    'Control', 'Meta', 'Shift', 'Alt', 'Escape', 'ContextMenu',
    'c', 'v', 'x', 'z', 'y', 'a', 's', 'p', 'r', 'f', 't', 'w',
    'Tab', 'F11', 'F5',
  ];

  useEffect(() => {
    const activeKeys = new Set<string>(); // Tracks currently pressed keys

    const handleKeyDown = (e: KeyboardEvent) => {
      activeKeys.add(e.key);

      // Disable specified keys
      if (allKeysToDisable.some((key) => activeKeys.has(key))) {
        e.preventDefault();
      }

      // Disable "Ctrl+any key" or "Cmd+any key" combinations
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      activeKeys.delete(e.key);
    };

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault(); // Disable right-click and touchpad gestures
    };

    const handleCopyPaste = (e: ClipboardEvent) => {
      e.preventDefault(); // Disable copy, cut, paste
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        tabSwitchCount.current += 1; // Increment tab switch count using the ref
        setTabSwitches(tabSwitchCount.current); // Update state to trigger re-render
        if (onTabSwitch) {
          onTabSwitch();
        }
      }
    };

    const target = document; // Attach to the entire document to cover the full screen

    // Add event listeners globally to the document
    target.addEventListener('keydown', handleKeyDown);
    target.addEventListener('keyup', handleKeyUp);
    target.addEventListener('contextmenu', handleContextMenu); // Disable right-click
    target.addEventListener('copy', handleCopyPaste);
    target.addEventListener('cut', handleCopyPaste);
    target.addEventListener('paste', handleCopyPaste);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // Remove event listeners
      target.removeEventListener('keydown', handleKeyDown);
      target.removeEventListener('keyup', handleKeyUp);
      target.removeEventListener('contextmenu', handleContextMenu);
      target.removeEventListener('copy', handleCopyPaste);
      target.removeEventListener('cut', handleCopyPaste);
      target.removeEventListener('paste', handleCopyPaste);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onTabSwitch]);

  return { tabSwitches }; // Return only the state related to tab switches
};

export default useBaseProctoring;
