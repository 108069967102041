import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Box,
  TextField,
  SelectChangeEvent,
  Container,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@mui/material";
import { useParams } from "react-router-dom";
import RichTextField from "../../../components/RichTextField";
import TestcaseSection from "../../../components/TestcaseSection";
import { Testcase } from "../../../constants/ProblemUtils";
import { ProblemFormData, initialData } from "../../../constants/ProblemUtils";
import { difficultyLevels } from "../../../constants/difficultyLevels";
import { tagOptions } from "../../../constants/tagOptions";
import { ProblemStatus, ProgrammingLanguage } from "../../../utils/types";
import { apiService } from "../../../services/Service";
import CustomSnackbar from "../../../components/CustomSnackbar";
import { SnackbarMessage } from "../../../customHooks/useSnackbar";
import { handleAuthError } from "../../../utils/authUtils";
import { useTheme } from "@mui/material/styles"; // Import useTheme
import { useUser } from "../../../contextApi/UserContext";

const languageNames: Record<ProgrammingLanguage, string> = {
  [ProgrammingLanguage.JAVASCRIPT]: "JavaScript",
  [ProgrammingLanguage.C]: "C",
  [ProgrammingLanguage.CPP]: "C++",
  [ProgrammingLanguage.JAVA]: "Java",
  [ProgrammingLanguage.PYTHON]: "Python",
};

const UpdateProblem: React.FC = () => {
  const theme = useTheme(); // Get the theme object
  const { problemId } = useParams<{ problemId: string }>();
  const [formData, setFormData] = useState<ProblemFormData>(initialData);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { user } = useUser();
  const [snackbarMessageInfo, setSnackbarMessageInfo] =
    useState<SnackbarMessage | null>(null);

  useEffect(() => {
    if (problemId) {
      apiService
        .get(`/problem/${problemId}`)
        .then((response: any) => {
          const data = response.data;

          // Ensure levels is set to a default value if it doesn't exist
          const updatedData = {
            ...data,
            levels: data.levels || ['LV4'], // Default to ['LV4'] if levels is not present
          };

          setFormData(updatedData);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setSnackbarMessageInfo({
            type: "error",
            message:
              error.response?.data.error || "Failed to fetch problem data.",
          });
          setSnackbarOpen(true);
          handleAuthError(
            { error: error.response?.data.error },
            window.location.href
          );
        });
    }
  }, [problemId]);

  const handleOpenSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRichTextChange =
    (
      field: keyof Omit<
        ProblemFormData,
        "publicTestcases" | "privateTestcases" | "defaultCode"
      >
    ) =>
      (content: string) => {
        setFormData((prevData) => ({ ...prevData, [field]: content }));
      };

  const handleTestcaseChange = (
    type: "public" | "private",
    index: number,
    field: keyof Testcase,
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: prevData[`${type}Testcases`].map((testcase, i) =>
        i === index ? { ...testcase, [field]: value } : testcase
      ),
    }));
  };

  const handleTagChange = (e: SelectChangeEvent<string[]>) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      tags: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleAddTestcase = (type: "public" | "private") => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: [
        ...prevData[`${type}Testcases`],
        { input: "", output: "", explanation: "" },
      ],
    }));
  };

  const handleRemoveTestcase = (type: "public" | "private", index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: prevData[`${type}Testcases`].filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleCodeChange = (language: ProgrammingLanguage, code: string) => {
    setFormData((prevData) => ({
      ...prevData,
      defaultCode: prevData.defaultCode.map((defaultCode) =>
        defaultCode.language === language
          ? { ...defaultCode, code }
          : defaultCode
      ),
    }));
  };

  const handleSubmit = (problemStatus: ProblemStatus) => {
    setLoading(true);
    apiService
      .put(`/problem/${problemId}`, { ...formData, status: problemStatus })
      .then((response) => {
        setSnackbarMessageInfo({
          type: "success",
          message: "Problem Saved successfully!",
        });
        setSnackbarOpen(true);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: "error",
          message: error.response?.data.error || "Failed to update problem.",
        });
        setSnackbarOpen(true);
        handleAuthError(
          { error: error.response?.data.error },
          window.location.href
        );
      });
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: theme.palette.background.paper,
          borderRadius: 4,
          boxShadow: `0 4px 8px ${theme.palette.grey[300]}`,
          marginBottom: 4,
          marginTop: 4,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "800px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Update Problem
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Slug"
              name="slug"
              value={formData.slug}
              onChange={handleInputChange}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Difficulty Level</InputLabel>
              <Select
                name="difficultyLevel"
                value={formData.difficultyLevel}
                onChange={handleInputChange}
                label="Difficulty Level"
              >
                {difficultyLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Tags</InputLabel>
              <Select
                name="tags"
                multiple
                value={formData.tags}
                onChange={handleTagChange}
                renderValue={(selected) => (selected as string[]).join(", ")}
                label="Tags"
              >
                {tagOptions.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <RichTextField
              label="Description"
              value={formData.description}
              onChange={handleRichTextChange("description")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Input Format"
              value={formData.inputFormat}
              onChange={handleRichTextChange("inputFormat")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Output Format"
              value={formData.outputFormat}
              onChange={handleRichTextChange("outputFormat")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Constraints"
              value={formData.constraints}
              onChange={handleRichTextChange("constraints")}
            />
          </Grid>

          <Grid item xs={12}>
            <TestcaseSection
              title="Public Testcases"
              testcases={formData.publicTestcases as Testcase[]}
              onAdd={() => handleAddTestcase("public")}
              onRemove={(index) => handleRemoveTestcase("public", index)}
              onChange={(index, field, value) =>
                handleTestcaseChange("public", index, field, value)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TestcaseSection
              title="Private Testcases"
              testcases={formData.privateTestcases as Testcase[]}
              onAdd={() => handleAddTestcase("private")}
              onRemove={(index) => handleRemoveTestcase("private", index)}
              onChange={(index, field, value) =>
                handleTestcaseChange("private", index, field, value)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Default Code</Typography>
            {formData.defaultCode.map((code) => (
              <Box key={code.language} sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  {languageNames[code.language]}:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  value={code.code}
                  onChange={(e) =>
                    handleCodeChange(code.language, e.target.value)
                  }
                  variant="outlined"
                />
              </Box>
            ))}
          </Grid>
         {user?.role === "admin" && (
          <Grid item xs={12}>
            <Typography variant="h6">Levels</Typography>
            <Box display="flex" flexDirection="row" gap={2}>
              {["LV1", "LV2", "LV3", "LV4"].map((level) => (
                <FormControlLabel
                  key={level}
                  control={
                    <Radio
                      checked={formData.level === level}
                      onChange={(e) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          level: level, // Set the selected level
                        }));
                      }}
                    />
                  }
                  label={level}
                />
              ))}
            </Box>
          </Grid>
        )}


          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            {user?.role === "author" && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSubmit(ProblemStatus.DRAFT)}
                >
                  Save As Draft
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(ProblemStatus.UNDER_REVIEW)}
                  sx={{ ml: 2 }}
                >
                  Submit For Review
                </Button>
              </>
            )}
            {user?.role === "admin" && (
              <>
                {formData.status === "Approved" ? (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleSubmit(ProblemStatus.PUBLISHED)}
                    sx={{ ml: 2 }}
                  >
                    Publish
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit(ProblemStatus.APPROVED)}
                      sx={{ ml: 2 }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => handleSubmit(ProblemStatus.REJECTED)}
                      sx={{ ml: 2 }}
                    >
                      Reject
                    </Button>
                  </>
                )}
              </>
            )}

          </Grid>
        </Grid>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default UpdateProblem;
