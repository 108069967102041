import { useState, useRef, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as blazeface from '@tensorflow-models/blazeface';
import * as cocoSsd from '@tensorflow-models/coco-ssd';

interface DetectionStats {
  faces: number;
  cellPhoneDetected: boolean;
  eyesOutOfScreen: boolean;
  gazeDirection: string;
  persons: number;
}

export const useAdvanceProctoring = () => {
  const [stats, setStats] = useState<DetectionStats>({
    faces: 0,
    cellPhoneDetected: false,
    eyesOutOfScreen: false,
    gazeDirection: 'Centered',
    persons: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const startCamera = async (): Promise<void> => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' },
          audio: false,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error('Error accessing camera:', err);

      }
    };

    const detect = async (
      faceModel: blazeface.BlazeFaceModel,
      objectModel: cocoSsd.ObjectDetection
    ) => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (!video || !canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const faces = await faceModel.estimateFaces(video, false);
      const objects = await objectModel.detect(video);

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const detectedDevices = objects.filter(
        (obj) => obj.class === 'cell phone' || obj.class === 'person'
      );

      const cellPhoneDetected = detectedDevices.some((d) => d.class === 'cell phone');
      const persons = detectedDevices.filter((d) => d.class === 'person').length;

      let eyesOutOfScreen = false;
      let gazeDirection = 'Centered';

      if (faces.length > 0) {
        const face = faces[0];
        if (face.landmarks && Array.isArray(face.landmarks)) {
          const landmarks = face.landmarks as number[][];
          const leftEye = landmarks[0];
          const rightEye = landmarks[5];
          const eyeCenter = [(leftEye[0] + rightEye[0]) / 2, (leftEye[1] + rightEye[1]) / 2];

          if (eyeCenter[0] < canvas.width / 3) gazeDirection = 'Left';
          else if (eyeCenter[0] > (2 * canvas.width) / 3) gazeDirection = 'Right';

          eyesOutOfScreen =
            leftEye[0] < 0 ||
            leftEye[0] > video.videoWidth ||
            rightEye[0] < 0 ||
            rightEye[0] > video.videoWidth;
        }
      }

     
      setStats({
        faces: faces.length,
        cellPhoneDetected,
        eyesOutOfScreen,
        gazeDirection,
        persons,
      });

      requestAnimationFrame(() => detect(faceModel, objectModel));
    };

    const loadModelsAndStartDetection = async () => {
      await tf.ready();
      const faceModel = await blazeface.load();
      const objectModel = await cocoSsd.load();
      setLoading(false);
      startCamera();

      if (videoRef.current) {
        videoRef.current.onloadedmetadata = () => detect(faceModel, objectModel);
      }
    };

    loadModelsAndStartDetection();

    return () => {
      const stream = videoRef.current?.srcObject as MediaStream;
      stream?.getTracks().forEach((track) => track.stop());
    };
  }, []);

  return { stats, loading, videoRef, canvasRef };
};
