import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Modal } from "@mui/material";
import { debounce } from "lodash";

declare global {
    interface Window {
        saveDataAccrossSessions: boolean;
    }
}

const CalibrationComponent: React.FC = () => {
    const [calibrationPoints, setCalibrationPoints] = useState<number[]>([]);
    const [isCalibrated, setIsCalibrated] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isCalibrated) {
            let webgazer: any;
            window.saveDataAccrossSessions = true;

            const initializeWebGazer = async () => {
                const webgazerModule = await import("webgazer");
                webgazer = webgazerModule.default;

                (window as any).webgazer = webgazer;
                webgazer
                    .setGazeListener(debouncedGazeListener)
                    .showVideoPreview(false)
                    .begin();
            };

            initializeWebGazer();

            return () => {
                if (webgazer) webgazer.end();
            };
        }
    }, [isCalibrated]);

    const debouncedGazeListener = debounce((data: any) => {
        if (data) {
            const { x, y } = data;

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const tolerance = 0;

            const outOfViewport =
                x < tolerance ||
                x > screenWidth - tolerance ||
                y < tolerance ||
                y > 2 * screenHeight - tolerance;

            if (outOfViewport) {
                setIsModalOpen(true);
            }
        }
    }, 10);

    const calibrationPositions = [
        { id: 1, x: window.innerWidth / 2, y: window.innerHeight / 2 }, // Center
        { id: 2, x: 50, y: 50 }, // Top-left
        { id: 3, x: window.innerWidth / 2, y: 50 }, // Top-middle
        { id: 4, x: window.innerWidth - 50, y: 50 }, // Top-right
        { id: 5, x: 50, y: window.innerHeight / 2 }, // Left-middle
        { id: 6, x: 50, y: window.innerHeight - 50 }, // Bottom-left
        { id: 7, x: window.innerWidth / 2, y: window.innerHeight - 50 }, // Bottom-middle
        { id: 8, x: window.innerWidth - 50, y: window.innerHeight - 50 }, // Bottom-right
        { id: 9, x: window.innerWidth - 50, y: window.innerHeight / 2 }, // Right-middle
    ];

    const handleCalibrationClick = (id: number) => {
        if (!calibrationPoints.includes(id)) {
            setCalibrationPoints((prev) => [...prev, id]);
        }
    };

    const startCalibration = () => {
        if (calibrationPoints.length === calibrationPositions.length) {
            setIsCalibrated(true);
        } else {
            alert("Please click on all calibration points.");
        }
    };

    return (
        <div
            style={{
                textAlign: "center",
                padding: 0,
                margin: 0,
                width: "100vw",
                height: "100vh",
                overflow: "hidden",
                position: "relative",
            }}
        >
            {!isCalibrated ? (
                <>
                    <Box
                        sx={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {calibrationPositions.map((point) => (
                            <Button
                                key={point.id}
                                sx={{
                                    position: "absolute",
                                    left: point.x,
                                    top: point.y,
                                    transform: "translate(-50%, -50%)",
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    backgroundColor: calibrationPoints.includes(point.id)
                                        ? "green"
                                        : "red",
                                }}
                                onClick={() => handleCalibrationClick(point.id)}
                            />
                        ))}
                    </Box>
                    {/* Center Text and Button Below the Middle Point */}
                    <Box
                        sx={{
                            position: "absolute",
                            top: `${window.innerHeight / 2 + 60}px`, // Adjust position below middle
                            left: "50%",
                            transform: "translateX(-50%)",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h4" sx={{ marginBottom: 2 }}>
                            Screen Calibration
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            Click on all points to calibrate the screen.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={startCalibration}
                        >
                            Start Eye Tracking
                        </Button>
                    </Box>
                </>
            ) : (
                <h1>Eye Tracking Started</h1>
            )}

            <Modal open={isModalOpen}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        padding: 4,
                        borderRadius: 2,
                        boxShadow: 24,
                        width: 300,
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h6" color="error">
                        Violation: You're looking away from the screen!
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        This behavior violates test rules. Please keep your eyes on the screen.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 2 }}
                        onClick={() => setIsModalOpen(false)}
                    >
                        I Acknowledge
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default CalibrationComponent;
