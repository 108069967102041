import React, { useState, useRef, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as blazeface from '@tensorflow-models/blazeface';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { CircularProgress } from '@mui/material'; // For loading spinner

interface DetectionObject {
  class: string;
  bbox: [number, number, number, number]; // [x, y, width, height]
}

interface Face {
  landmarks?: number[][] | tf.Tensor2D;
  topLeft: [number, number] | tf.Tensor;
  bottomRight: [number, number] | tf.Tensor;
}

const ProctorApp = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [faces, setFaces] = useState<number>(0);
  const [cellPhoneDetected, setCellPhoneDetected] = useState<boolean>(false);
  const [eyesOutOfScreen, setEyesOutOfScreen] = useState<boolean>(false);
  const [gazeDirection, setGazeDirection] = useState<string>('Centered'); // Tracking gaze direction
  const [persons, setPersons] = useState<number>(0);

  useEffect(() => {
    // Initialize the camera and start video feed
    const startCamera = async (): Promise<void> => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' },
          audio: false,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    };

    // Detect faces and objects (electronic devices)
    const detectFaces = async (
      faceModel: blazeface.BlazeFaceModel,
      objectModel: cocoSsd.ObjectDetection
    ): Promise<void> => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (!video || !canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Ensure video is fully loaded and valid dimensions are set
      if (video.videoWidth === 0 || video.videoHeight === 0) {
        console.warn('Invalid video dimensions: video not fully loaded');
        return; // Exit early if video dimensions are not set
      }

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const faces = await faceModel.estimateFaces(video, false);
      const objects = await objectModel.detect(video);

      // Clear canvas before drawing anything
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the video feed on canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      // Handle face detection and tracking
      setFaces(faces.length);
     

      // Detect electronic devices (cell phone,)
      const detectedDevices = objects.filter(
        (obj: DetectionObject) => obj.class === 'cell phone' || obj.class === 'person'
          
      );
      if (detectedDevices.length > 0 && detectedDevices.some(device => device.class === 'cell phone')) {
        setCellPhoneDetected(true);
      }
      else{
        setCellPhoneDetected(false);
      }
      if (detectedDevices.length > 0 && detectedDevices.some(device => device.class === 'person')) {
        setPersons(persons + 1);
      }
      else{
        setPersons(persons - 1);
      }

      if (faces.length > 0) {
        const face = faces[0];
        if (face.landmarks && Array.isArray(face.landmarks)) {
          const landmarks = face.landmarks as number[][];
      
          // Log the landmarks for debugging
          console.log(landmarks);
      
          // Assuming landmarks: 
          // [0]: Left corner of left eye
          // [1]: Right corner of left eye
          // [4]: Left corner of right eye
          // [5]: Right corner of right eye
      
          const leftEye = landmarks[0];  // Left corner of left eye
          const rightEye = landmarks[5]; // Right corner of right eye
      
          // Calculate the horizontal distance between the eyes to detect gaze direction
          const eyeCenter = [(leftEye[0] + rightEye[0]) / 2, (leftEye[1] + rightEye[1]) / 2];
      
          // Define simple thresholds to classify gaze direction
          if (eyeCenter[0] < canvas.width / 3) {
            setGazeDirection('Left');
          } else if (eyeCenter[0] > 2 * canvas.width / 3) {
            setGazeDirection('Right');
          } else {
            setGazeDirection('Centered');
          }
        } else {
          console.warn('No landmarks found for the detected face.');
        }
      }
      
      

      // Draw bounding boxes for faces
      faces.forEach((face: Face) => {
        const landmarks = face.landmarks;
        if (landmarks && Array.isArray(landmarks)) {
          
          const leftEye = landmarks[0]; // Left eye
          const rightEye = landmarks[1]; // Right eye

          // Check if eyes are out of the screen (for example, if coordinates are negative or exceed video dimensions)
          const isEyesOutOfScreen =
            leftEye[0] < 0 || leftEye[0] > video.videoWidth ||
            rightEye[0] < 0 || rightEye[0] > video.videoWidth ||
            leftEye[1] < 0 || leftEye[1] > video.videoHeight ||
            rightEye[1] < 0 || rightEye[1] > video.videoHeight;

          setEyesOutOfScreen(isEyesOutOfScreen); // Update state for eye gazing
        }

        const { topLeft, bottomRight } = face;
        ctx.strokeStyle = 'green';
        ctx.lineWidth = 2;
        if (Array.isArray(topLeft) && Array.isArray(bottomRight)) {
          ctx.strokeRect(
            topLeft[0],
            topLeft[1],
            bottomRight[0] - topLeft[0],
            bottomRight[1] - topLeft[1]
          );
        }
      });

      // Draw bounding boxes for detected objects (electronic devices)
      objects.forEach((obj: DetectionObject) => {
        const [x, y, width, height] = obj.bbox;
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.strokeRect(x, y, width, height);
        ctx.font = '16px Arial';
        ctx.fillStyle = 'blue';
        ctx.fillText(obj.class, x, y - 10);
      });

      // Continuously detect faces and objects
      requestAnimationFrame(() => detectFaces(faceModel, objectModel));
    };

    // Load the models and start detection
    const loadModelsAndStartDetection = async (): Promise<void> => {
      await tf.ready(); // Ensure TensorFlow.js is ready
      const faceModel = await blazeface.load();
      const objectModel = await cocoSsd.load();
      setLoading(false); // Set loading to false once models are loaded
      startCamera();

      // Wait until the video is fully loaded (onLoadedMetadata event)
      if (videoRef.current) {
        videoRef.current.onloadedmetadata = () => {
          detectFaces(faceModel, objectModel);
        };
      }
    };

    loadModelsAndStartDetection();

    return () => {
      // Cleanup camera stream on component unmount
      const stream = videoRef.current?.srcObject as MediaStream;
      stream?.getTracks().forEach((track) => track.stop());
    };
  }, []);

  return (
    <div style={{ position: 'relative', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h1>Proctoring App</h1>
      
      {loading ? (
        <CircularProgress style={{ position: 'absolute' }} />
      ) : (
        <>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{
              width: '500px',
              height: 'auto',
              borderRadius: '8px',
              border: '2px solid black',
              position: 'absolute', 
                top: 0,
                left: 0
            
            }}
          />
          <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }} />
        </>
      )}

      {/* Status Box */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <p>Faces Detected: {faces}</p>
        <p>Cell Phone Detected: {cellPhoneDetected ? 'Yes' : 'No'}</p>
        <p>Eye Out of Screen: {eyesOutOfScreen ? 'Yes' : 'No'}</p>
        <p>Gaze Direction: {gazeDirection}</p>
        <p>Persons Detected: {persons}</p>
      </div>
    </div>
  );
};

export default ProctorApp;
