import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { handleAuthError } from "../../../utils/authUtils";
import {
  Box,
  CircularProgress,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from "@mui/material";
import { AccessTime, CheckCircleOutline, ErrorOutline, Timer } from "@mui/icons-material";
import RenderQuestions from "./RenderQuestions";
import CodingProblemRender from "./CodingProblemRender";
import McqProblemRenderer from "./McqProblemRenderer";
import { formatTime } from "../../../utils/helperFunctions";
import { uploadResponsesToS3 } from "./helpers/uploadResponsesToS3";
import { tab } from "@testing-library/user-event/dist/tab";

const MemoizedCodingProblemRender = React.memo(CodingProblemRender);
const MemoizedMcqProblemRenderer = React.memo(McqProblemRenderer);
const MemoizedRenderQuestions = React.memo(RenderQuestions);
declare global {
  interface Window {
    mediaRecorder: MediaRecorder;
    mediaStream: MediaStream;
  }
}

const AssessmentHomePage = () => {
  const { assessmentslug } = useParams<{ assessmentslug: string }>();
  const [assessmentData, setAssessmentData] = useState<any>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(0);
  const [timerRunning, setTimerRunning] = useState<boolean>(false);
  const [AssessmentEnded, setAssessmentEnded] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [userMcqResponse, setUserMcqResponse] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [proctoringPermissionsGranted, setProctoringPermissionsGranted] = useState(false);
  const [isProctoredAssessment, setIsProctoredAssessment] = useState(false);
  const [videoGranted, setVideoGranted] = useState(false);
  const [fullscreenGranted, setFullscreenGranted] = useState(false);
  const [showExitWarning, setShowExitWarning] = useState(false);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);


  // Request Camera and Fullscreen Permissions
  const requestPermissions = async () => {
    if (timer <= 0) {
      console.log("Permissions request skipped because timer is not active.");
      return;
    }
    try {
      let videoGranted = false;
      let fullscreenGranted = false;
  
      // Check for existing video permission by attempting to access the camera
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoGranted = true; // If no error occurs, video permission is granted
        // Stop the video stream to release the camera
        stream.getTracks().forEach((track) => track.stop());
      } catch (videoError) {
        console.error("Video permission not granted:", videoError);
      }
  
      // Check if fullscreen is active
      fullscreenGranted = document.fullscreenElement !== null;
  
      // Request fullscreen mode if not already active
      if (!fullscreenGranted && document.documentElement.requestFullscreen) {
        try {
          await document.documentElement.requestFullscreen();
          fullscreenGranted = true; // If no error occurs, fullscreen is granted
        } catch (fullscreenError) {
          console.error("Fullscreen permission not granted:", fullscreenError);
        }
      }
  
      // Set permissions granted if both conditions are met
      if (videoGranted && fullscreenGranted) {
        setProctoringPermissionsGranted(true);
      } else {
        throw new Error("Required permissions not fully granted.");
      }
    } catch (error) {
      console.error("Error requesting permissions:", error);
      alert("Permissions are required to proceed with the assessment.");
    }
  };

  const handleVisibilityChange = () => {
    if(tabSwitchCount > 0 && tabSwitchCount < 3){
      alert("If you switch tabs, the assessment will be submitted automatically.");
    }
    if (document.visibilityState === "hidden" && timer > 0) {
      // Increment tab switch count when the user leaves the tab
      setTabSwitchCount((prevCount) => prevCount + 1);
      
    }
  };

  useEffect(() => {
    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    if (tabSwitchCount > 2 && timer > 0) {
      // Submit the assessment if the user switches tabs twice
      onSubmitAssessment();
    }
  }, [tabSwitchCount]);

  useEffect(() => {
    const checkPermissions = async () => {
      // Check for video permission
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setVideoGranted(true);
      } catch {
        setVideoGranted(false);
      }

      // Check for fullscreen permission
      setFullscreenGranted(document.fullscreenElement !== null);
    };

    if (timer > 0) checkPermissions();

    // Listen for fullscreen change and key events
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && !showExitWarning) {
        setShowExitWarning(true); // Show warning instead of exiting fullscreen directly
      }
    };

    const handleKeyDown = (event:any) => {
      if (event.key === "Escape" && document.fullscreenElement) {
        event.preventDefault(); // Prevent exiting fullscreen immediately
        setShowExitWarning(true); // Show warning dialog
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showExitWarning]);

  const handleRequestPermissions = async () => {
    try {
      await requestPermissions();
      setVideoGranted(true);
      setFullscreenGranted(document.fullscreenElement !== null);
    } catch (error) {
      console.error("Error requesting permissions:", error);
    }
  };

  const handleExitWarningAgree = () => {
    setShowExitWarning(false);
    // Logic to keep the user in fullscreen
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
  };

  const handleExitWarningDisagree = () => {
    setShowExitWarning(false);
  
    // Check if fullscreen is active before trying to exit
    if (document.fullscreenElement) {
      document.exitFullscreen().then(() => {
       onSubmitAssessment();
      }).catch((error) => {
        console.error("Error exiting fullscreen:", error);
      });
    } else {
      // If not in fullscreen, directly submit the assessment
      onSubmitAssessment();
    }
  };
  


  useEffect(() => {
    const interval = setInterval(() => {
      const lastUploadTime = parseInt(localStorage.getItem("lastUploadTime") || "0", 10);
      const now = Date.now();
      const elapsed = now - lastUploadTime;

      // Upload responses only if 5 minutes have passed since the last upload
      if (elapsed >= 5 * 60 * 1000) {
        const responses = JSON.parse(
          localStorage.getItem("userMcqResponse") || "{}"
        );
        uploadResponsesToS3(presignedUrl, responses);
      }
    }, 60 * 1000); // Check every minute

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [presignedUrl]);



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await apiService.get(`/assessments/questions/${assessmentslug}`);
        setPresignedUrl(response.data.presignedUrl);
        const { startTime: starttime, endTime: endtime, duration, isProctored,
          randomizeQuestions
           } = response.data.details;
        // Parse startTime, endTime, and duration
        const startTime = new Date(starttime).getTime();
        const endTime = new Date(endtime).getTime();
        const currentTime = Date.now();
        const durationInMs = duration * 60 * 1000; // Convert duration from minutes to milliseconds

        // Check if a start time exists in localStorage
        const storedStartTime = localStorage.getItem(`assessment_start_${assessmentslug}`);
        let userStartTime = storedStartTime ? parseInt(storedStartTime, 10) : currentTime;

        // If no stored start time, set the current time as the start time and save it
        if (!storedStartTime) {
          localStorage.setItem(`assessment_start_${assessmentslug}`, userStartTime.toString());
        }

        if (currentTime < startTime) {
          // Assessment has not started yet
          setTimer((startTime - currentTime) / 1000); // Time until start
          setTimerRunning(false);
        } else if (currentTime >= startTime && currentTime < endTime) {
          // Assessment is ongoing
          const maxEndTime = Math.min(userStartTime + durationInMs, endTime); // Calculate max possible end time
          setTimer((maxEndTime - currentTime) / 1000); // Remaining time for the user
          setTimerRunning(true);
        } else {
          // Assessment has ended
          setTimer(0);
          setTimerRunning(false);
          setAssessmentEnded(true);
        }
        if(randomizeQuestions){
          response.data.details.questions = response.data.details.questions.sort(() => Math.random() - 0.5);
          response.data.mcq = response.data.mcq.sort(() => Math.random() - 0.5);
          response.data.coding = response.data.coding.sort(() => Math.random() - 0.5);
        }

        setAssessmentData(response.data);
        setSelectedQuestion(
          response.data.coding.length > 0
            ? response.data.coding[0]
            : response.data.mcq[0]
        );
        if (response.data.details.type === "mcq" || response.data.details.type === "mixed") {
          let mcqs = response.data.details.questions.filter((mcq: any) => mcq.type === "mcq");
          let initialResponses = mcqs.map((mcq: any) => {
            return {
              mcqId: mcq.questionId,
              selectedOption: "",
            };
          })
          if (!userMcqResponse.length) {
            setUserMcqResponse(initialResponses);
            localStorage.setItem("userMcqResponse", JSON.stringify(initialResponses));
          }
        }
        if (isProctored) {
          setIsProctoredAssessment(true);
        }
        setLoading(false);
      } catch (error: any) {
        if (error instanceof AxiosError) {
          handleAuthError({ error: error.response?.data.error }, window.location.href);
        }
        setErrorMessage(error.response?.data.error || "Failed to fetch assessment data");
        setLoading(false);
      }
    };
    fetchData();
  }, [assessmentslug]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer <= 0) {
      setTimerRunning(false);
    }
    return () => clearInterval(interval);
  }, [timerRunning, timer]);

  useEffect(() => {
    if (timer < 0) {
      onSubmitAssessment();
    }
    if (timer <= 0) {
      setAssessmentEnded(true);
    } else {
      setAssessmentEnded(false);
    }
  }, [timer]);

  const renderQuestionContent = useMemo(() => {
    if (!selectedQuestion) {
      return <Typography>Select a question to begin.</Typography>;
    }

    if (assessmentData?.coding.includes(selectedQuestion)) {
      return <MemoizedCodingProblemRender slug={selectedQuestion.slug} />;
    }

    if (assessmentData?.mcq.includes(selectedQuestion)) {
      return <MemoizedMcqProblemRenderer selectedQuestion={selectedQuestion} userMcqResponse={userMcqResponse} />;
    }
  }, [selectedQuestion, assessmentData]);


  useEffect(() => {
    localStorage.setItem("userMcqResponse", JSON.stringify(userMcqResponse));
  }, [userMcqResponse]);

  const submitAssessment = async () => {
    const submitData = {
      assessmentId: assessmentData.details._id,
      mcqResponses: JSON.parse(localStorage.getItem("userMcqResponse") || "[]"),
    }

    try {
      await apiService.post(`/assessments/submit`, submitData);
      setAssessmentEnded(true);
      setErrorMessage("Assessment submitted successfully");
    } catch (error) {
      console.error("Error submitting assessment:", error);
    }
  }

  const clearLocalStorage = () => {
    // Remove a specific key
    localStorage.removeItem("userMcqResponse");

    // Iterate through all keys and remove those starting with "mcq"
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("mcq") || key.startsWith("assessment_start")) {
        localStorage.removeItem(key);
      }
    });
  };

  const onSubmitAssessment = async () => {
    try {
      // Check if MediaRecorder instance exists and is recording
      if (window.mediaRecorder && window.mediaRecorder.state === "recording") {
        window.mediaRecorder.stop(); // Stop the recording
        console.log("Video recording stopped.");
        
        // Stop all associated media tracks
        if (window.mediaStream) {
          window.mediaStream.getTracks().forEach((track) => track.stop());
          console.log("Media stream tracks stopped.");
        }
      }
  
      // Continue with assessment submission logic
      handleClose();
      await submitAssessment();
      clearLocalStorage();
    } catch (error) {
      console.error("Error during assessment submission:", error);
    }
  };
  
  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography display={"flex"} justifyContent={"center"} variant="h4" gutterBottom>
          {errorMessage}
        </Typography>
      </Box>
    );
  }
  if (AssessmentEnded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography display={"flex"} justifyContent={"center"} variant="h4" gutterBottom>
          Assessment has ended
        </Typography>
      </Box>
    );
  }

  if (isProctoredAssessment && !proctoringPermissionsGranted) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5" gutterBottom>
          Proctored Assessment - Grant Required Permissions
        </Typography>
        {!videoGranted && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Camera access is required to proceed.
          </Alert>
        )}
        {!fullscreenGranted && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Fullscreen mode is required to proceed.
          </Alert>
        )}
        <Button variant="contained" color="primary" onClick={handleRequestPermissions}>
          Grant Permissions
        </Button>
      </Box>
    );
  }




  return (
    <Box display="flex">
      {/* Left Panel */}
      <Drawer
        anchor="left"
        open={true}
        variant="permanent"
        sx={{
          width: 280,
          "& .MuiDrawer-paper": {
            width: 280,
            boxSizing: "border-box",
          },
        }}
      >
        <Box p={1}>
          <MemoizedRenderQuestions
            assessmentData={assessmentData}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        </Box>
      </Drawer>

      {/* Right Panel */}
      <Box p={0} flex={2} justifyContent={"space-between"}>
        {/* Timer */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 1,
            backgroundColor: "background.paper",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
              {assessmentData.details.name}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item display="flex" alignItems="center" gap={1}>
                <AccessTime sx={{ color: "success.main" }} />
                <Typography variant="body2" color="text.secondary">
                  Start: {new Date(assessmentData.details.startTime).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item display="flex" alignItems="center" gap={1}>
                <Typography variant="body2" color="text.secondary">
                  End: {new Date(assessmentData.details.endTime).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" alignItems="center" gap={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              disabled={timer <= 0}
            >
              Submit Assessment
            </Button>

            <Chip
              label={timer > 0 ? "Ongoing" : "Completed"}
              color={timer > 0 ? "success" : "error"}
              icon={timer > 0 ? <CheckCircleOutline /> : <ErrorOutline />}
              sx={{ fontWeight: "bold", paddingX: 1 }}
            />

            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: "primary.light",
              }}
            >
              <IconButton disabled sx={{ color: "primary.dark" }}>
                <Timer sx={{ fontSize: 30, color: "primary.dark" }} />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "text.primary",
                  width: "120px",
                  textAlign: "center",
                  fontFamily: "monospace",
                }}
              >
                {timer > 0 ? `${formatTime(timer)}` : "Time's up!"}
              </Typography>
            </Paper>
          </Box>
        </Box>

        {/* Question Content */}
        <Paper elevation={2} sx={{ p: 1 }}>
          {renderQuestionContent}
        </Paper>
      </Box>

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Submit Assessment?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit the assessment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmitAssessment}>Yes Submit</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showExitWarning} onClose={() => {}} disableEscapeKeyDown>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <Typography>
            Exiting fullscreen will automatically submit the assessment. Do you want to stay in fullscreen?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExitWarningAgree} color="primary">
            Stay in Fullscreen
          </Button>
          <Button onClick={handleExitWarningDisagree} color="secondary">
            Exit and Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssessmentHomePage;
