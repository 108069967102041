import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  useTheme,
} from "@mui/material";
import { apiService } from "../../services/Service";
import useDebounce from "../../customHooks/useDebounce";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../utils/types";
import { handleAuthError } from "../../utils/authUtils";
import { Link } from "react-router-dom";
import moment from "moment";

const Assessments: React.FC = () => {
  const [assessments, setAssessments] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [createdBy, setCreatedBy] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalAssessments, setTotalAssessments] = useState<number>(0);

  const debouncedSearch = useDebounce(search, 500);
  const theme = useTheme();

  useEffect(() => {
    const fetchAssessments = async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams({
          page: page.toString(),
          limit: rowsPerPage.toString(),
          search: debouncedSearch,
          type,
          createdBy,
          startDate,
          endDate,
        }).toString();

        const response = await apiService.get<{
          assessments: any[];
          totalAssessments: number;
        }>(`/assessments?${params}`);
        console.log(response);
        setAssessments(response.assessments);
        setTotalAssessments(response.totalAssessments);
      } catch (err) {
        setError("Failed to fetch assessments");
        const error = err as AxiosError<ErrorResponse>;
        if (error.response?.data) {
          handleAuthError({ error: error.response.data.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, [page, rowsPerPage, debouncedSearch, type, createdBy, startDate, endDate]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setType(e.target.value as string);
  };

  const handleCreatedByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreatedBy(e.target.value);
  };

  const handleDateChange = (field: "startDate" | "endDate") => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    field === "startDate" ? setStartDate(value) : setEndDate(value);
  };

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        <Typography variant="h6">{error}</Typography>
      </Box>
    );

  return (
    <Box p={3}>
      <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
        Assessments
      </Typography>
      <Box mb={3} display="flex" gap={2} flexWrap="wrap">
        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          sx={{ flex: 1, minWidth: 200 }}
        />
        <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Type</InputLabel>
          <Select value={type} onChange={(e: any) => handleTypeChange(e)} label="Type">
            <MenuItem value="">All</MenuItem>
            <MenuItem value="mcq">MCQ</MenuItem>
            <MenuItem value="coding">Coding</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Start Date"
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={handleDateChange("startDate")}
          sx={{ flex: 1, minWidth: 200 }}
        />
        <TextField
          label="End Date"
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={handleDateChange("endDate")}
          sx={{ flex: 1, minWidth: 200 }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Slug</strong></TableCell>
              <TableCell><strong>Type</strong></TableCell>
              <TableCell><strong>Created By</strong></TableCell>
              <TableCell><strong>Start Time</strong></TableCell>
              <TableCell><strong>End Time</strong></TableCell>
              <TableCell><strong>Duration</strong></TableCell>
              <TableCell><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.map((assessment, index) => (
              <TableRow
                key={assessment.slug}
                sx={{
                  backgroundColor: index % 2 === 0 ? theme.palette.background.paper : theme.palette.action.hover,
                }}
              >
                <TableCell>
                  <Tooltip title={assessment.name}>
                    <Typography variant="subtitle1">{assessment.name}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell ><Link to={`/assessment/details/${assessment.slug}`}>{assessment.slug}</Link></TableCell>
                <TableCell>{assessment.type}</TableCell>
                <TableCell>{assessment.createdBy?.fullname}</TableCell>
                <TableCell>{moment(assessment.startTime).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                <TableCell>{moment(assessment.endTime).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                <TableCell>{assessment.duration}</TableCell>

                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/assessments/update/${assessment._id}`}
                    sx={{ borderRadius: 4, textTransform: "none" }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalAssessments}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};

export default Assessments;
