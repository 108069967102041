import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  Container,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  SelectChangeEvent,
} from "@mui/material";
import { apiService } from "../../../../services/Service";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import { SnackbarMessage } from "../../../../customHooks/useSnackbar";

const MCQTags: React.FC = () => {
  const [search, setSearch] = useState("");
  const [section, setSection] = useState("");
  const [sections, setSections] = useState<string[]>([]); // State to store sections
  const [mcqTags, setMcqTags] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);

  const limit = 10; // Items per page

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  const fetchSections = () => {
    apiService
      .get(`/tags/mcq/sections`) // Replace with the actual endpoint for fetching sections
      .then((response:any) => {
        setSections(response.data);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.error || "Failed to fetch sections";
        setSnackbarMessageInfo({ type: "error", message: errorMessage });
        setSnackbarOpen(true);
      });
  };

  const fetchMCQTags = () => {
    setLoading(true);

    const params = new URLSearchParams({
      ...(search && { search }),
      ...(section && { section }),
      page: page.toString(),
      limit: limit.toString(),
    });

    apiService
      .get(`/tags/mcq?${params.toString()}`)
      .then((response:any) => {
        const { data, pages } = response;
        setMcqTags(data);
        setTotalPages(pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage =
          error.response?.data?.error || "Failed to fetch MCQ Tags";
        setSnackbarMessageInfo({ type: "error", message: errorMessage });
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchSections(); // Fetch sections on component mount
  }, []);

  useEffect(() => {
    fetchMCQTags(); // Fetch tags when filters change
  }, [page, search, section]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 on search
  };

  const handleSectionChange = (e: SelectChangeEvent<string>) => {
      setSection(e.target.value as string);
      setPage(1); // Reset to page 1 on section filter
    };

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>
          Manage MCQ Tags
        </Typography>

        <Grid container spacing={3} sx={{ my: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Search"
              value={search}
              onChange={handleSearchChange}
              fullWidth
              variant="outlined"
              placeholder="Search tags or sections"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Section</InputLabel>
              <Select
                value={section}
                onChange={(e)=>handleSectionChange(e)}
                label="Section"
              >
                <MenuItem value="">All Sections</MenuItem>
                {sections.map((sec:any) => (
                  <MenuItem key={sec._id} value={sec?.section}>
                    {sec?.section}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Section</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mcqTags.map((tag, index) => (
                  <TableRow key={index}>
                    <TableCell>{tag.section}</TableCell>
                    <TableCell>{tag.tags.join(", ")}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        href={`/mcq-tags/update/${tag._id}`}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default MCQTags;
