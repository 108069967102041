import React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const PaymentSuccess = () => {
  const location = useLocation();

  // Extract the payment reference ID from the URL
  const queryParams = new URLSearchParams(location.search);
  const reference = queryParams.get("reference");

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Paper elevation={3} sx={{ p: 4, borderRadius: 3, textAlign: "center" }}>
        <CheckCircleOutlineIcon
          sx={{ fontSize: 60, color: "green", mb: 2 }}
        />
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Payment Successful!
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Thank you for your payment. Your reference ID is:
        </Typography>
        <Typography
          variant="h6"
          fontWeight="bold"
          color="primary"
          sx={{ mt: 1, mb: 3 }}
        >
          {reference}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => (window.location.href = "/")}
        >
          Go to Dashboard
        </Button>
      </Paper>
    </Box>
  );
};

export default PaymentSuccess;
