import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

import {
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TablePagination,
  SelectChangeEvent,
} from "@mui/material";
import { apiService } from "../../services/Service";
import { AxiosError } from "axios";
import { handleAuthError } from "../../utils/authUtils";

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0); // Total count of users from backend
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          page: currentPage.toString(),
          limit: rowsPerPage.toString(),
          search: search || "",
        });

        const url = `/client/users?${queryParams.toString()}`;
        const response: any = await apiService.get(url);
        setUsers(response.users || []);
        setTotalUsers(response.totalUsers || 0); // Use total users count from backend
      } catch (err) {
        const error = err as AxiosError;
        const errorResponse: any = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [currentPage, search, role, rowsPerPage]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    setRole(e.target.value as string);
    setCurrentPage(1);
  };



  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1); // MUI's TablePagination is 0-indexed
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Container>
      <Box mb={4} width="100%" display="flex" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
        <TextField
          variant="outlined"
          placeholder="Search users..."
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: <FaSearch style={{ color: "#9e9e9e" }} />,
          }}
          sx={{ flex: 1 }}
        />
      </Box>

      {/* User List Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Picture</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>User Groups</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : users.length > 0 ? (
              users.map((user: any) => (
                <TableRow key={user._id}>
                  <TableCell>
                    <img
                      src={user.picture || "https://via.placeholder.com/100"}
                      alt={user.fullname}
                      style={{ width: 60, borderRadius: "50%" }}
                    />
                  </TableCell>
                  <TableCell>{user.fullname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.userGroups && user.userGroups.length > 0
                      ? user.userGroups.join(", ")
                      : "Not part of any group"}
                  </TableCell>


                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" mt={4}>
        <TablePagination
          component="div"
          count={totalUsers} // Use total user count from backend
          rowsPerPage={rowsPerPage}
          page={currentPage - 1} // MUI's TablePagination is 0-indexed
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 25, 50]}
          labelRowsPerPage="Users per page"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
          }
        />
      </Box>
    </Container>
  );
};

export default UserManagement;
