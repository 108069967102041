import React from 'react';
import useBaseProctoring from './Proctoring/UseBaseProctoring';
import useWebGazer from "./Proctoring/UseWebGazer";
import { useAdvanceProctoring } from './Proctoring/UseAdvanceProctoring';
import { CircularProgress } from '@mui/material';
const hiddenVideoStyle: React.CSSProperties = {
    position: 'fixed',
    top: '-10000px',
    left: '-10000px',
    width: '10px',
    height: 'auto',
    opacity: 0,
    pointerEvents: 'none',
};

const TestApp = () => {
    const { isCalibrated, calibrationUI } = useWebGazer({
        onOutOfViewport: () => {
            alert("You are looking away from the screen!");
        },
    });
    const { tabSwitches } = useBaseProctoring({
        onTabSwitch: () => {
            alert('Tab switch detected');
        },
    });
    const { stats, loading, videoRef, canvasRef } = useAdvanceProctoring();



    // If calibration is not done, return the calibration UI
    if (!isCalibrated) {
        return calibrationUI;
    }

    // Once calibrated, return the test UI
    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <p>Proctoring is active on this component.</p>
            <p>Tab switches detected: {tabSwitches}</p>
            <p>Proctoring is active. Stay focused on the screen!</p>
            <div style={{ position: 'relative', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h1>Proctoring App</h1>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            // style={{ width: '50px', height: 'auto', borderRadius: '8px', border: '2px solid black', }}
                            style={hiddenVideoStyle}
                        />
                        <canvas ref={canvasRef}
                            //    style={{ position: 'absolute', top: 0, left: 0 }}
                            style={hiddenVideoStyle}
                        />
                    </>
                )}

                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <p>Faces Detected: {stats.faces}</p>
                    <p>Cell Phone Detected: {stats.cellPhoneDetected ? 'Yes' : 'No'}</p>
                    <p>Eyes Out of Screen: {stats.eyesOutOfScreen ? 'Yes' : 'No'}</p>
                    <p>Gaze Direction: {stats.gazeDirection}</p>
                    <p>Persons Detected: {stats.persons}</p>
                </div>
            </div>
        </div>
    );
};

export default TestApp;
