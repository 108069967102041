// Function to upload responses to S3
export const uploadResponsesToS3 = async (url: string, responses: any) => {
    if (!url) return;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(responses),
      });

      if (response.ok) {
        console.log("Responses uploaded successfully");
        localStorage.setItem("lastUploadTime", Date.now().toString()); // Update the last upload time
      } else {
        console.error("Failed to upload responses", response.status);
      }
    } catch (error) {
      console.error("Error uploading responses:", error);
    }
  };
