import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiService } from "../services/Service";
import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Chip,
  Grid,
  Stack,
  Snackbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const SubscribeToPlan = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState<any>(null);
  const [clientType, setClientType] = useState<string>("college");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const todayDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
  const [formData, setFormData] = useState<any>({
    officialName: "",
    shortName: "",
    address: "",
    authorizedPerson: "",
    designation: "",
    contactNumber: "",
    authorizedEmail: "",
    officialEmail: "",
    planId: id,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };


  useEffect(() => {
    setLoading(true);
    apiService
      .get(`/plans/${id}`)
      .then((response: any) => {
        setPlan(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching plan details:", error);
        setLoading(false);
      });
  }, [id]);

  const handleInputChange = (field: string, value: string) => {
    setFormData((prev: any) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const requiredFields = [
      "officialName",
      "shortName",
      "address",
      "authorizedPerson",
      "designation",
      "contactNumber",
      "authorizedEmail",
      "officialEmail",
    ];

    const isValidPhoneNumber = /^[0-9]{10}$/.test(formData.contactNumber); // Validate phone number format
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.authorizedEmail); // Validate authorized email
    const isValidOfficialEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.officialEmail); // Validate official email

    const isValid =
      requiredFields.every((field) => formData[field]?.trim() !== "") &&
      isValidPhoneNumber &&
      isValidEmail &&
      isValidOfficialEmail;

    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [formData]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);


  const calculateEndDate = () => {
    const startDate = new Date(plan.startDate);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + plan.validity);
    return endDate.toLocaleDateString();
  };
  const generateClientId = () => {
    const prefix = clientType === "college" ? "C" : clientType === "university" ? "U" : "IN";
    return `${prefix}-${todayDate}-${id}`;
  };

  const handlePayment = () => {
    const data = {
      ...formData,
      clientId: generateClientId(),
      clientType: clientType,
    };

    apiService
      .post("/razorpay/checkout", { amount: plan.price.inr }) // Pass the amount to create an order
      .then((response: any) => {
        const { order_id, key_id } = response; // Get order_id and key_id from backend

        const options = {
          key: key_id, // Razorpay key_id from backend
          amount: plan.price.inr * 100, // Amount in paise
          currency: "INR",
          name: plan.title,
          description: `Subscription for ${plan.title} Plan`,
          order_id: order_id, // Pass the order_id from backend
          handler: (response: any) => {
            const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
              response;

            // Send payment details to the backend for verification
            apiService
              .post("/razorpay/verify", {
                razorpay_order_id,
                razorpay_payment_id,
                razorpay_signature,
                amount:plan.price.inr,
                formData: data, // Optional: Pass form data for additional processing

              })
              .then((verificationResponse: any) => {
                // Redirect to payment success page or handle success
                window.location.href = `/paymentsuccess?reference=${razorpay_payment_id}`;
              })
              .catch((error) => {
                console.error("Verification failed:", error);
              });
          },
          prefill: {
            name: formData.authorizedPerson,
            email: formData.authorizedEmail,
            contact: formData.contactNumber,
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      })
      .catch((error) => {
        console.error("Error creating order:", error);
      });

    if (!window.Razorpay) {
      console.error("Razorpay is not loaded");
      return;
    }
  };

  const handleFreePlan = () => {
    const data = {
      ...formData,
      clientId: generateClientId(),
      clientType: clientType,
    };
    apiService.post("/plans/subscribe/free", { formData:data})
      .then((response: any) => {
        setSnackbarMessage("Subscribed to free plan successfully!");
        setSnackbarOpen(true);
        window.location.href = `/login`;
      })
      .catch((error) => {
        setSnackbarMessage(
          error.response?.data.error || "Failed to Subscribe to free plan"
        );
        setSnackbarOpen(true);
        console.error("Error subscribing to free plan:", error);

      })
  }


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!plan) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5" color="error">
          Failed to load plan details.
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3} maxWidth="800px" mx="auto">
      {/* Plan Details Section */}
      <Paper elevation={3} sx={{ p: 4, borderRadius: 3, mb: 5 }}>
        <Typography variant="h4" color="primary" fontWeight="bold" gutterBottom>
          {plan.title} Plan
        </Typography>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: plan.details }} />
        <Grid container spacing={2} mt={3}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Number of Users:
            </Typography>
            <Typography variant="body1">{plan.numberOfUsers}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Number of Assessments:
            </Typography>
            <Typography variant="body1">{plan.numberOfAssessments}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Levels Included:
            </Typography>
            <Stack direction="row" spacing={1} mt={1}>
              {plan.levels.map((level: string) => (
                <Chip key={level} label={level} color="primary" variant="outlined" />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Validity:
            </Typography>
            <Typography variant="body1">
              {new Date(plan.startDate).toLocaleDateString()} to {calculateEndDate()}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" color="success.main" fontWeight="bold" mt={3}>
          Price: USD ${plan.price.usd} / INR ₹{plan.price.inr}
        </Typography>
      </Paper>

      {/* Form Section */}
      <Paper elevation={3} sx={{ p: 4, borderRadius: 3, mb: 5 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Fill Your Details
        </Typography>
        <RadioGroup
          value={clientType}
          onChange={(e) => setClientType(e.target.value)}
          row
        >
          <FormControlLabel value="college" control={<Radio />} label="College" />
          <FormControlLabel value="university" control={<Radio />} label="University" />
          <FormControlLabel value="industry" control={<Radio />} label="Industry" />
        </RadioGroup>

        <TextField
          fullWidth
          label="Client Official Name"
          variant="outlined"
          margin="normal"
          onChange={(e) => handleInputChange("officialName", e.target.value)}
        />
        <TextField
          fullWidth
          label="Client Name (Short)"
          variant="outlined"
          margin="normal"
          onChange={(e) => handleInputChange("shortName", e.target.value)}
        />
        <TextField
          fullWidth
          label="Full Address"
          variant="outlined"
          multiline
          rows={4}
          margin="normal"
          onChange={(e) => handleInputChange("address", e.target.value)}
        />
        <TextField
          fullWidth
          label="Authorized Person Name"
          variant="outlined"
          margin="normal"
          onChange={(e) => handleInputChange("authorizedPerson", e.target.value)}
        />
        <TextField
          fullWidth
          label="Designation"
          variant="outlined"
          margin="normal"
          onChange={(e) => handleInputChange("designation", e.target.value)}
        />
        <TextField
          fullWidth
          label="Authorized Contact Number"
          variant="outlined"
          margin="normal"
          error={!/^[0-9]{10}$/.test(formData.contactNumber) && formData.contactNumber !== ""}
          helperText={
            !/^[0-9]{10}$/.test(formData.contactNumber) && formData.contactNumber !== ""
              ? "Enter a valid 10-digit phone number"
              : ""
          }
          onChange={(e) => handleInputChange("contactNumber", e.target.value)}
        />
        <TextField
          fullWidth
          label="Authorized Email ID"
          variant="outlined"
          margin="normal"
          error={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.authorizedEmail) && formData.authorizedEmail !== ""}
          helperText={
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.authorizedEmail) && formData.authorizedEmail !== ""
              ? "Enter a valid email address"
              : ""
          }
          onChange={(e) => handleInputChange("authorizedEmail", e.target.value)}
        />
        <TextField
          fullWidth
          label="Official Email ID"
          variant="outlined"
          margin="normal"
          error={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.officialEmail) && formData.officialEmail !== ""}
          helperText={
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.officialEmail) && formData.officialEmail !== ""
              ? "Enter a valid email address"
              : ""
          }
          onChange={(e) => handleInputChange("officialEmail", e.target.value)}
        />
      </Paper>

      {/* Payment Section */}
      {plan.price.inr !== 0 && <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Proceed to Payment
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          onClick={handlePayment}
          disabled={!isFormValid} // Disable button if form is invalid
        >
          Pay ₹{plan.price.inr} / ${plan.price.usd}
        </Button>
      </Paper>}
      {plan.price.inr === 0 && <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Subscribe to this plan
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          onClick={handleFreePlan}
          disabled={!isFormValid} // Disable button if form is invalid
        >
          Subscribe
        </Button>
      </Paper>}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default SubscribeToPlan;
