import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Container,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { apiService } from "../../../../services/Service";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import { SnackbarMessage } from "../../../../customHooks/useSnackbar";

const Updatetags: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get section ID from the URL

  const [section, setSection] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);

  // Close snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  // Fetch section by ID and populate fields
  const fetchSectionById = () => {
    setLoading(true);
    apiService
      .get(`/tags/mcq/section/${id}`) // Replace this endpoint with your actual backend endpoint
      .then((response:any) => {
        console.log(response);
        const { section, tags } = response.data;
        setSection(section);
        setTags(tags);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: "error",
          message: error.response?.data?.error || "Failed to fetch section details",
        });
        setSnackbarOpen(true);
      });
  };

  // Update the section and tags
  const handleSubmit = () => {
    setLoading(true);
    apiService
      .put(`/tags/mcq/${id}`, { section, tags })
      .then(() => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: "success",
          message: "Section updated successfully!",
        });
        setSnackbarOpen(true);
      })
      .catch((error) => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: "error",
          message: error.response?.data?.error || "Failed to update section",
        });
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchSectionById();
  }, [id]);

  return (
    <Container sx={{ marginTop: 4 }}>
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 4,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h5" gutterBottom>
              Update Section and Tags
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Section Name"
                  fullWidth
                  value={section}
                  onChange={(e) => setSection(e.target.value)}
                />
              </Grid>

              {tags.map((tag, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    label={`Tag ${index + 1}`}
                    fullWidth
                    value={tag}
                    onChange={(e) =>
                      setTags((prevTags) => {
                        const updatedTags = [...prevTags];
                        updatedTags[index] = e.target.value;
                        return updatedTags;
                      })
                    }
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ marginTop: 1 }}
                    onClick={() =>
                      setTags((prevTags) => prevTags.filter((_, i) => i !== index))
                    }
                  >
                    Remove Tag
                  </Button>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => setTags((prevTags) => [...prevTags, ""])}
                >
                  Add Tag
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ marginTop: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                Update Section
              </Button>
            </Box>
          </>
        )}
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default Updatetags;
