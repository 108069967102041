import React, { useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    Grid,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import Papa from "papaparse";
import { SnackbarMessage } from "../../customHooks/useSnackbar";
import CustomSnackbar from "../../components/CustomSnackbar";
import { apiService } from "../../services/Service";


const CreateUsers: React.FC = () => {
    const [emails, setEmails] = useState<string[]>([]);
    const [manualEmails, setManualEmails] = useState<string>("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);

    // Helper function to remove duplicates
    const removeDuplicates = (emailList: string[]) => {
        return Array.from(new Set(emailList));
    };

    // Handle manual input for space-separated emails
    const handleManualEmailInput = () => {
        const emailList = manualEmails
            .split(" ")
            .map((email) => email.trim())
            .filter((email) => /\S+@\S+\.\S+/.test(email)); // Basic email validation
        setEmails((prevEmails) => removeDuplicates([...prevEmails, ...emailList]));
        setManualEmails(""); // Reset input
    };

    // Handle file upload and parse CSV
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const parsedEmails = results.data
                        .map((row: any) => row.Email || row.email)
                        .filter((email: string) => /\S+@\S+\.\S+/.test(email)); // Basic email validation
                    setEmails((prevEmails) => removeDuplicates([...prevEmails, ...parsedEmails]));
                },
                error: (error) => {
                    console.error("Error parsing CSV file:", error);
                },
            });
        }
    };

    // API call to create users
    const createUsersApi = (emailList: string[]) => {
        setLoading(true); // Show loading state
        apiService
            .post('/client/create-users', { emails: emailList }) // API call
            .then((response: any) => {
                setLoading(false); // Hide loading state
                console.log("Users created successfully!",response);
                setSnackbarMessageInfo({
                    type: "success",
                    message: `Users created successfully! RemainingQuota: ${response.remainingQuota}`,
                });
                setSnackbarOpen(true); // Show success snackbar
            })
            .catch((error: any) => {
                setLoading(false); // Hide loading state
                const errorMessage = error.response?.data?.error || "An error occurred while creating users.";
                setSnackbarMessageInfo({
                    type: "error",
                    message: errorMessage,
                });
                setSnackbarOpen(true); // Show error snackbar
            });
    };
    

    // Handle API submission
    const handleCreateUsers = () => {
        if (emails.length > 0) {
            createUsersApi(emails);
        } else {
            setSnackbarMessageInfo({
                type: "error",
                message: "No emails to submit.",
            });
            setSnackbarOpen(true);
        }
    };

    // Snackbar handlers
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
        setSnackbarMessageInfo(null);
    };

    return (
        <Box p={3} maxWidth={900} m="auto">
            <Typography variant="h4" align="center" mb={3}>
                Create Users
            </Typography>
            <Grid container spacing={3}>
                {/* Left side: Uploaded emails list */}
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Uploaded Emails ({emails.length})
                        </Typography>
                        {emails.length > 0 ? (
                            <List sx={{ maxHeight: 350, overflow: "auto", flexGrow: 1 }}>
                                {emails.map((email, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {index + 1}.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <ListItemText primary={email} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        ) : (
                            <Typography>No emails uploaded yet.</Typography>
                        )}
                    </Paper>
                </Grid>

                {/* Right side: Input and file upload */}
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                        }}
                    >
                        <Box mb={2}>
                            <Typography variant="h6" gutterBottom>
                                Enter Emails Manually
                            </Typography>
                            <TextField
                                label="Emails (space-separated)"
                                variant="outlined"
                                fullWidth
                                value={manualEmails}
                                onChange={(e) => setManualEmails(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleManualEmailInput}
                                fullWidth
                            >
                                Add Emails
                            </Button>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Upload CSV File
                            </Typography>
                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<UploadFile />}
                                fullWidth
                                sx={{ mt: 1 }}
                            >
                                Upload CSV
                                <input type="file" hidden accept=".csv" onChange={handleFileUpload} />
                            </Button>
                        </Box>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleCreateUsers}
                            fullWidth
                            sx={{ mt: 3 }}
                        >
                            Submit Users
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
            {/* Snackbar */}
            <CustomSnackbar
                open={snackbarOpen}
                messageInfo={snackbarMessageInfo}
                handleClose={handleCloseSnackbar}
            />
        </Box>
    );
};

export default CreateUsers;
