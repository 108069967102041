import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Typography,
  Container,
  CircularProgress,
  Snackbar,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { Save, Clear } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../../services/Service";
import { handleAuthError } from "../../../utils/authUtils";

interface ClientDataForm {
  clientType: "College" | "University" | "Industry";
  officialName: string;
  shortName: string;
  address: string;
  authorizedPersonName: string;
  authorizedContactNumber: string;
  designation: string;
  authorizedEmailId: string;
  officialEmailId: string;
  isApproved: boolean;
  numberOfAssessments: number;
  numberOfUsers: number;
  validity: string; // ISO format for easier use with TextField
  levels: string[];
}

const initialClientData: ClientDataForm = {
  clientType: "College",
  officialName: "",
  shortName: "",
  address: "",
  authorizedPersonName: "",
  authorizedContactNumber: "",
  designation: "",
  authorizedEmailId: "",
  officialEmailId: "",
  isApproved: false,
  numberOfAssessments: 0,
  numberOfUsers: 0,
  validity: new Date().toISOString().split("T")[0], // Default to today's date
  levels: [],
};

const UpdateClient: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ClientDataForm>(initialClientData);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  // Fetch client data
  useEffect(() => {
    if (id) {
      setLoading(true);
      apiService
        .get<{ client: { clientData: ClientDataForm } }>(`/admin/client/${id}`)
        .then((response) => {
          const { client } = response;
          if (client && client.clientData) {
            setFormData({
              ...client.clientData,
              validity: new Date(client.clientData.validity).toISOString().split("T")[0],
            });
          }
        })
        .catch((error) => handleAuthError(error, window.location.href))
        .finally(() => setLoading(false));
    }
  }, [id, navigate]);

  // Handle form field updates
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle checkbox updates
  // Handle checkbox updates for levels
const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, checked } = e.target;
  
  setFormData((prevData) => {
    const updatedLevels = checked
      ? [...prevData.levels, name]  // Add to levels if checked
      : prevData.levels.filter(level => level !== name);  // Remove from levels if unchecked

    return {
      ...prevData,
      levels: updatedLevels,
    };
  });
};


  // Handle form submission
  const handleSubmit = () => {
    setLoading(true);
    apiService
      .put(`/admin/client/${id}`, { clientData: formData })
      .then(() => {
        setSnackbarMessage("Client updated successfully!");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        handleAuthError(error, window.location.href);
        setSnackbarMessage("Failed to update client.");
        setSnackbarOpen(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container maxWidth="md">
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Update Client
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Official Name"
                  name="officialName"
                  fullWidth
                  value={formData.officialName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Short Name"
                  name="shortName"
                  fullWidth
                  value={formData.shortName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  name="address"
                  fullWidth
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Authorized Person Name"
                  name="authorizedPersonName"
                  fullWidth
                  value={formData.authorizedPersonName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Authorized Contact Number"
                  name="authorizedContactNumber"
                  fullWidth
                  value={formData.authorizedContactNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Designation"
                  name="designation"
                  fullWidth
                  value={formData.designation}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Authorized Email ID"
                  name="authorizedEmailId"
                  fullWidth
                  value={formData.authorizedEmailId}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Official Email ID"
                  name="officialEmailId"
                  fullWidth
                  value={formData.officialEmailId}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Validity"
                  name="validity"
                  type="date"
                  fullWidth
                  value={formData.validity}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              {/* New Fields */}
              <Grid item xs={6}>
                <TextField
                  label="Number of Assessments"
                  name="numberOfAssessments"
                  type="number"
                  fullWidth
                  value={formData.numberOfAssessments}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Number of Users"
                  name="numberOfUsers"
                  type="number"
                  fullWidth
                  value={formData.numberOfUsers}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">Select Levels</Typography>
                {["LV1", "LV2", "LV3", "LV4"].map((level) => (
                  <FormControlLabel
                    key={level}
                    control={
                      <Checkbox
                        checked={formData.levels.includes(level)}
                        onChange={handleCheckboxChange}
                        name={level}
                      />
                    }
                    label={level}
                  />
                ))}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isApproved}
                      name="isApproved"
                      onChange={(e) => setFormData({ ...formData, isApproved: e.target.checked })}
                    />
                  }
                  label="Is Approved"
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Save />}
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Clear />}
            onClick={() => navigate("/admin/clients")}
            disabled={loading}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default UpdateClient;
