import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Divider,
} from "@mui/material";
import { Delete as DeleteIcon, UploadFile } from "@mui/icons-material";
import Papa from "papaparse";
import { SnackbarMessage } from "../../customHooks/useSnackbar";
import CustomSnackbar from "../../components/CustomSnackbar";
import { apiService } from "../../services/Service";

const UserGroupManagement: React.FC = () => {
  const [emails, setEmails] = useState<string[]>([]); // List of emails entered
  const [manualEmails, setManualEmails] = useState<string>(""); // Manual email input
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]); // Selected groups for new users
  const [userGroups, setUserGroups] = useState<string[]>([]); // List of available user groups
  const [loading, setLoading] = useState(false); // Loading state for API calls
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);

  // Fetch user groups from backend API
  const fetchUserGroups = () => {
    setLoading(true);
    apiService
      .get("/client/user-groups")
      .then((response: any) => {
        setLoading(false);
        const groups = Array.isArray(response.userGroups) ? response.userGroups : [];
        setUserGroups(groups);
      })
      .catch(() => {
        setLoading(false);
        setUserGroups([]);
      });
  };

  useEffect(() => {
    fetchUserGroups();
  }, []);

  // Helper function to remove duplicate emails
  const removeDuplicates = (emailList: string[]) => {
    return Array.from(new Set(emailList));
  };

  // Handle manual email input (space-separated emails)
  const handleManualEmailInput = () => {
    const emailList = manualEmails
      .split(" ")
      .map((email) => email.trim())
      .filter((email) => /\S+@\S+\.\S+/.test(email)); // Basic email validation
    setEmails((prevEmails) => removeDuplicates([...prevEmails, ...emailList]));
    setManualEmails(""); // Clear the input field after adding emails
  };

  // Handle CSV file upload and parse emails
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedEmails = results.data
            .map((row: any) => row.Email || row.email)
            .filter((email: string) => /\S+@\S+\.\S+/.test(email)); // Basic email validation
          setEmails((prevEmails) => removeDuplicates([...prevEmails, ...parsedEmails]));
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
        },
      });
    }
  };

  // Handle group selection change
  const handleGroupSelectionChange = (event: any) => {
    const value = event.target.value;
    setSelectedGroups(value);
  };

  // API call to create users with selected groups
  const createUsersApi = (emailList: string[]) => {

    setLoading(true);
    apiService
      .post("/client/create-group-users", { emails: emailList, userGroups: selectedGroups })
      .then((response: any) => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: "success",
          message: `Users Groups Updated Successfully!`,
        });
        setSnackbarOpen(true);
      })
      .catch((error: any) => {
        setLoading(false);
        const errorMessage = error.response?.data?.error || "An error occurred while creating users.";
        setSnackbarMessageInfo({
          type: "error",
          message: errorMessage,
        });
        setSnackbarOpen(true);
      });
  };

  // Handle API submission to create users
  const handleCreateUsers = () => {
    if (emails.length > 0 && selectedGroups.length > 0) {
      createUsersApi(emails);
    } else {
      setSnackbarMessageInfo({
        type: "error",
        message: "Please select at least one email and one group to submit.",
      });
      setSnackbarOpen(true);
    }
  };

  // Snackbar close handler
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  // Handle email removal
  const handleRemoveEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  return (
    <Box p={3} maxWidth={900} m="auto">
      <Typography variant="h4" align="center" mb={3}>
        Uses Group Management
      </Typography>

      <Box mb={2}>
        <Typography variant="h6">Enter Emails Manually</Typography>
        <TextField
          label="Emails (space-separated)"
          variant="outlined"
          fullWidth
          value={manualEmails}
          onChange={(e) => setManualEmails(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleManualEmailInput}
          fullWidth
        >
          Add Emails
        </Button>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box>
        <Typography variant="h6">Upload CSV File</Typography>
        <Button
          variant="outlined"
          component="label"
          startIcon={<UploadFile />}
          fullWidth
          sx={{ mt: 2 }}
        >
          Upload CSV
          <input type="file" hidden accept=".csv" onChange={handleFileUpload} />
        </Button>
      </Box>

      <Box mt={3}>
        <Typography variant="h6">Select Groups for Users</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Select Groups</InputLabel>
          <Select
            multiple
            value={selectedGroups}
            onChange={handleGroupSelectionChange}
            renderValue={(selected) => selected.join(", ")}
          >
            {userGroups.map((group, index) => (
              <MenuItem key={index} value={group}>
                <Checkbox checked={selectedGroups.indexOf(group) > -1} />
                <ListItemText primary={group} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mb={3}>
        <Typography variant="h6">Emails to be Assigned ({emails.length})</Typography>
        {emails.length > 0 ? (
          <List sx={{ maxHeight: 350, overflow: "auto" }}>
            {emails.map((email, index) => (
              <ListItem key={email}>
                <ListItemText primary={email} />
                <IconButton onClick={() => handleRemoveEmail(email)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No emails added yet.</Typography>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="success"
          onClick={handleCreateUsers}
          sx={{ width: "100%", maxWidth: 400 }}
        >
          Submit Users
        </Button>
      </Box>

      {/* Snackbar */}
      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default UserGroupManagement;
