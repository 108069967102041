import React from "react";
import { Box, Typography, Paper, Avatar, Divider } from "@mui/material";
import moment from "moment";

interface FollowUp {
  addedBy: {
    fullname: string;
  };
  comment: string;
  timestamp: string; // Assuming timestamp is a string in ISO format
}

interface FormData {
  status: string;
  followUps: FollowUp[];
}

const FollowUpsSection = ({ formData }: { formData: FormData }) => {
  if (
    formData?.status === "rejected" &&
    formData?.followUps &&
    formData.followUps.length > 0
  ) {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
          Follow-Ups
        </Typography>
        {formData.followUps.map((followUp, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              mb: 3,
              p: 3,
              borderRadius: 3,
              backgroundColor: "background.paper",
            }}
          >
            {/* Header Section with Avatar and User Info */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>
                  {followUp.addedBy.fullname.charAt(0)}
                </Avatar>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", lineHeight: 1.2 }}
                  >
                    {followUp.addedBy.fullname}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    {moment(followUp.timestamp).format("MMM D, YYYY, h:mm A")}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Divider sx={{ mb: 2 }} />

            {/* Comment Section */}
            <Typography
              variant="body1"
              sx={{
                color: "text.primary",
                wordBreak: "break-word",
              }}
            >
              {followUp.comment}
            </Typography>
          </Paper>
        ))}
      </Box>
    );
  }

  return null;
};

export default FollowUpsSection;
