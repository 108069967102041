import React, { useEffect, useState } from 'react';
import { apiService } from '../../services/Service';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

const UserGroupManagement = () => {
  const [loading, setLoading] = useState(false);
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [newGroup, setNewGroup] = useState('');

  const fetchUserGroups = () => {
    setLoading(true);
    apiService
      .get('/client/user-groups')
      .then((response: any) => {
        setLoading(false);
        const groups = Array.isArray(response.userGroups) ? response.userGroups : [];
        setUserGroups(groups);
      })
      .catch(() => {
        setLoading(false);
        setUserGroups([]);
      });
  };

  useEffect(() => {
    fetchUserGroups();
  }, []);

  const handleAddGroup = () => {
    if (newGroup.trim() && Array.isArray(userGroups) && !userGroups.includes(newGroup.trim())) {
      setUserGroups([...userGroups, newGroup.trim()]);
      setNewGroup('');
    }
  };

  const handleRemoveGroup = (group: string) => {
    setUserGroups(userGroups.filter((g) => g !== group));
  };

  const handleUpdateUserGroups = () => {
    setLoading(true);
    apiService
      .put('/client/user-groups', { userGroups })
      .then(() => {
        fetchUserGroups();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        User Group Management
      </Typography>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6">Existing Groups</Typography>
        {userGroups.length > 0 ? (
          <List>
            {userGroups.map((group, index) => (
              <ListItem key={index} secondaryAction={
                <IconButton edge="end" onClick={() => handleRemoveGroup(group)}>
                  <DeleteIcon color="error" />
                </IconButton>
              }>
                <ListItemText primary={group} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No groups available.</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 3 }}>
        <TextField
          label="New Group"
          variant="outlined"
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddGroup}
          disabled={!newGroup.trim()}
          sx={{ minWidth: '120px' }}
        >
          Add Group
        </Button>
      </Box>

      <Box sx={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUpdateUserGroups}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>
      </Box>
    </Box>
  );
};

export default UserGroupManagement;
