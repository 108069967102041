// src/constants/roles.ts

export const roles = [
    { value: "admin", label: "Admin" },
    { value: "client", label: "Client" },
    { value: "student", label: "Student" },
    { value: "unknown", label: "Unknown" },
    { value: "author", label: "Author" },
    {value :"moderator", label: "Moderator"},
  ];

  
  