import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Container,
} from "@mui/material";
import { apiService } from "../../../../services/Service";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import { SnackbarMessage } from "../../../../customHooks/useSnackbar";
import { handleAuthError } from "../../../../utils/authUtils";

interface CreateTagsFormData {
  section: string;
  tags: string;
}

const Createtags: React.FC = () => {
  const [formData, setFormData] = useState<CreateTagsFormData>({
    section: "",
    tags: "",
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);

  const handleOpenSnackbar = (type: "success" | "error", message: string) => {
    setSnackbarMessageInfo({ type, message });
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    setLoading(true);

    // Convert tags to an array by splitting on commas
    const payload = { ...formData, tags: formData.tags.split(",").map((tag) => tag.trim()) };

    apiService
      .post("/tags/mcq", payload)
      .then(() => {
        setLoading(false);
        handleOpenSnackbar("success", "Tags created successfully!");
        setFormData({ section: "", tags: "" });
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response?.data?.error || "Failed to create tags.";
        handleOpenSnackbar("error", errorMessage);
        handleAuthError({ error: errorMessage }, window.location.href);
      });
  };

  return (
    <Container sx={{ width: "100%", maxWidth: "600px" }}>
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 4,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginTop: 4,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Create Tags
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Section Name"
              name="section"
              value={formData.section}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Tags (comma-separated)"
              name="tags"
              value={formData.tags}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>

        {!loading ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ marginTop: 3 }}
          >
            Submit
          </Button>
        ) : (
          <CircularProgress sx={{ marginTop: 3 }} />
        )}
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default Createtags;
